$white: #ffffff;
$blue: #1a7ef7;

html {
  height: 100%;
}

body {
  height: 100%;
  font-family: 'IBM Plex Sans';
  header,
  footer {
    flex: 0 0 auto;
  }
  main {
    flex: 1 0 auto;
  }
  #root {
    height: 100%;
  }
}

a.active.focus,
a.active:focus,
a.focus,
a:active.focus,
a:active:focus,
a:focus,
button.active.focus,
button.active:focus,
button.focus,
button:active.focus,
button:active:focus,
button:focus,
.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus {
  outline: 0 !important;
  outline-color: transparent !important;
  outline-width: 0 !important;
  outline-style: none !important;
  box-shadow: 0 0 0 0 rgba(0, 123, 255, 0) !important;
}

.container {
  max-width: 1200px !important;
}

main {
  background-color: #fafafa;
}

.header {
  position: relative;
  padding: 20px 0;
  padding-bottom: 10px;
  background: none;
  border-bottom: none;
  @media (min-width: 768px) {
    padding-bottom: 106px;
    border-bottom: none;
  }
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 360px;
    top: 0;
    left: 0;
    background: linear-gradient(0deg, #fafafa, #e8f1f7);
    @media (min-width: 768px) {
      height: 200px;
    }
  }

  &.search-focused {
    @media (max-width: 991px) {
      padding-bottom: 10px;
    }
  }

  .container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 3;
    a:not(.btn) {
      font-size: 14px;
    }
  }

  .login-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
    background-color: $blue;
    border-radius: 4px;
    color: $white;
    font-size: 14px;
    height: 42px;
    padding: 0;

    &:hover {
      color: $white;
    }

    @media (min-width: 768px) {
      height: 48px;
    }
    .fas {
      display: none;
      &:first-child {
        display: block;
      }
      @media (min-width: 768px) {
        display: block;
        color: $white;
        min-width: 15px;
      }
    }

    &.logged-in {
      height: 42px;

      @media (min-width: 768px) {
        height: 50px;
      }

      .login-btn__description {
        font-size: 12px;
        padding: 6px;

        @media (min-width: 768px) {
          padding: 10px 12px;
          font-size: 14px;
        }

        i {
          font-size: 14px;
        }
      }
    }

    .login-btn__description {
      align-self: stretch;
      flex-shrink: 0;
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 6px 12px;
      text-transform: none;
      font-weight: bold;
      transition: all 0.15s ease-in-out;
      &:hover {
        background-color: #0069d9;
        border-color: #0062cc;
      }

      @media (min-width: 768px) {
        padding: 10px 12px;
      }

      .fas {
        margin-right: 7px;
      }
    }

    .divider {
      width: 1px;
      height: 34px;
      background-color: #d0e5f0;
      border: none;

      @media (min-width: 768px) {
        height: 40px;
        margin: 0 1px;
      }
    }

    .logout {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 10px;
      height: 100%;
      transition: all 0.15s ease-in-out;
      &:hover {
        background-color: #0069d9;
        border-color: #0062cc;
      }
      @media (max-width: 767px) {
        padding: 0 6px;
      }
    }
  }
}

.header--logo-centered {
  .container {
    justify-content: center;
  }
}

.header--search {
  padding: 19px 0 18px 0;
  background: linear-gradient(0deg, #fafafa, #e8f1f7);

  &.breadcrumbs {
    padding: 19px 0 0 0;
    @media (max-width: 767px) {
      padding-top: 10px;
    }
  }

  &:before {
    display: none;
  }
  .search-field-form {
    margin: 0;
    height: 50px;
    box-shadow: none;
    width: 100%;
    max-width: 770px;
    justify-content: flex-end;
    background-color: transparent;
    border: none;
    @media (min-width: 768px) {
      height: 54px;
      margin-left: 15px;
      margin-right: 15px;
      background-color: #ffffff;
      border: 1px solid #d0e5f0;
    }
    .search-field-location {
      padding: 15px 0;
    }

    .search-field-btn {
      width: 42px;
      height: 42px;
      margin-right: 4px;
    }

    .search-field-wrapper {
      display: none;
      @media (min-width: 768px) {
        display: flex;
      }
    }
  }

  .search-field-dropdown-result {
    margin-top: -20px;
    .search-field-dropdown-result-description {
      font-size: 14px;
    }
    .search-field-dropdown-result-all {
      font-size: 12px;
    }
  }

  .row {
    align-items: center;
  }

  //divider cutted
}

.header--feature {
  padding-bottom: 70px;
}

.rating-index {
  font-size: 14px;
  line-height: 20px;
  color: #696666;
  padding: 9px 10px;
  height: 40px;
  background-color: #efeff4;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  strong {
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    color: #1a7ef7;
  }
  .far {
    font-size: 12px;
    color: #bce0f5;
    margin-left: 5px;
  }
}

.logotype {
  margin-right: 0;

  .demo-mode {
    position: absolute;
    bottom: -20px;
    text-align: center;
    color: red;
    font-size: 16px;
  }
}

.main-title-wrapper {
  .title {
    font-weight: bold;
    font-size: 26px;
    line-height: 34px;
    color: #1d1b1b;
    margin-bottom: 10px;
    @media (min-width: 768px) {
      font-size: 30px;
      line-height: 39px;
    }
  }

  .sub-title {
    font-size: 14px;
    line-height: 18px;
    color: #7f7f7f;
  }
}

.call-to-action {
  margin-top: 15px;
  margin-bottom: 30px;
  @media (min-width: 768px) {
    display: none;
    margin-bottom: 0;
  }

  &.search-focused {
    @media (max-width: 991px) {
      display: none;
    }
  }

  .link {
    display: block;
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    color: #1c5caa;
    margin-bottom: 15px;
    .fas {
      font-size: 12px;
      margin-left: 10px;
    }
  }

  .description {
    font-size: 14px;
    line-height: 18px;
    color: #7f7f7f;
    a {
      color: #1c5caa;
    }
  }
}

.intro-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
  @media (min-width: 768px) {
    margin-top: 0;
  }

  &.search-focused {
    @media (max-width: 991px) {
      display: none;
    }
  }

  .call-to-action {
    display: none;
    @media (min-width: 768px) {
      display: block;
    }
  }
}

.search-feature-wrapper {
  background-color: #edf4f8;
  position: relative;
  border-top: 1px solid #d0e5f0;
}

.search-field-form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #d0e5f0;
  box-shadow: 0px 4px 25px rgba(28, 92, 170, 0.2);
  border-radius: 5px;
  margin-top: 15px;
  margin-bottom: 30px;
  height: 60px;
  @media (min-width: 768px) {
    margin-top: 25px;
    margin-bottom: 85px;
  }
  input {
    border: none;
    font-size: 14px;
    line-height: 18px;
    color: #9b9b9b;
    &::-webkit-input-placeholder,
    &::-moz-placeholder,
    &:-moz-placeholder,
    &:-ms-input-placeholder {
      font-size: 14px;
      line-height: 18px;
      color: #9b9b9b;
    }
    &:focus {
      outline: none;
    }
  }

  .search-field {
    padding: 10px 0 10px 10px;
    @media (min-width: 768px) {
      padding: 20px 65px 20px 20px;
    }
  }

  .search-field-wrapper {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;

    .search-field-specialty {
      display: flex;
      margin: 7px;
      border-radius: 4px;
      align-items: center;
      background-color: #1b5caa;
      color: #fff;
      font-weight: bold;
      padding: 5px;
      outline: none;
      width: fit-content;
      font-size: 14px;
      line-height: 30px;

      .close {
        cursor: pointer;
        font-weight: 500;
        color: #fff;
        margin-left: 5px;
        line-height: 30px;
      }
    }
  }

  .search-field {
    width: 100%;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: #333333;
    background-color: transparent;
    &::placeholder {
      font-size: 14px;
      line-height: 18px;
      color: #9b9b9b;
      font-weight: normal;
      //@media (max-width: 374px) {
      //  white-space: pre-line;
      //  position: relative;
      //  top: -8px;
      //}
    }
  }

  .search-field-btn-close {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: transparent;
    border: none;

    &:hover,
    &:focus,
    &:active {
      background-color: transparent !important;
      box-shadow: none !important;
    }

    .fas {
      font-size: 16px;
      color: #d0e5ef;
      height: 12px;
    }
  }

  .divider {
    display: none;
    @media (min-width: 992px) {
      display: block;
      width: 2px;
      height: 36px;
      margin: 7px 15px;
      background-color: #d0e5f0;
      flex: 0 0 auto;
    }
  }

  .search-field-btn {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $blue;
    border-radius: 4px;
    flex: 0 0 auto;
    cursor: pointer;
    margin-right: 5px;
    &:hover {
      background-color: #0069d9;
      border-color: #0062cc;
    }
    @media (max-width: 767px) {
      width: 45px;
      height: 45px;
    }
    .fas {
      font-size: 16px;
      color: $white;
    }
  }

  .search-field-location-wrapper {
    display: none;
    @media (min-width: 992px) {
      position: relative;
      display: flex;
      align-items: center;
      padding-left: 24px;
      width: 220px;
      flex: 0 0 auto;
      background-color: transparent;
    }
    .fas {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      font-size: 18px;
      color: #9b9b9b;
    }
  }

  .search-field-location {
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: #333333;
    padding: 20px 0;
    &::placeholder {
      font-size: 14px;
      line-height: 18px;
      color: #9b9b9b;
      font-weight: normal;
    }
  }
}

.news-section {
  margin-bottom: 40px;
  @media (min-width: 768px) {
    margin-bottom: 100px;
  }

  &.search-focused {
    @media (max-width: 991px) {
      opacity: 0;
    }
  }

  .title {
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    color: #333333;
    margin-bottom: 24px;
  }

  .news-list__item {
    display: flex;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
    @media (min-width: 768px) {
      width: 48%;
      margin-bottom: 0;
      padding: 0 15px;
    }
  }

  .news-list__link {
    font-size: 14px;
    line-height: 18px;
    text-decoration-line: underline;
    color: #1c5caa;
    font-weight: 600;
    &:hover {
      text-decoration: none;
    }
    .fas {
      font-size: 12px;
      margin-right: 7px;
      color: #1c5caa;
    }
  }

  .news-list {
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    @media (min-width: 768px) {
      flex-direction: row;
    }
  }

  .news-list__icon {
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e8f1f7;
    border-radius: 5px;
    margin-right: 15px;
    flex: 0 0 auto;
  }

  .news-list__description {
    font-size: 12px;
    line-height: 22px;
    color: #7f7f7f;
    @media (min-width: 768px) {
      font-size: 14px;
      line-height: 26px;
    }
  }
}

.footer-dropdown {
  display: inline-block;
  .dropdown-btn {
    position: relative;
    background-color: transparent;
    border: 2px solid #1a7ef7;
    border-radius: 4px;
    min-width: 208px;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #1c5caa;
    text-align: left;
    padding: 10px 15px;
    &:active {
      background-color: transparent !important;
      color: #1c5caa !important;
    }
    .fas {
      margin-right: 10px;
      font-size: 14px;
      color: #1c5caa;
    }

    .fas--arrow {
      position: absolute;
      right: 5px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  ul {
    margin-bottom: 0;
    margin-top: 0;
  }

  .dropdown-menu {
    width: 100%;
  }
}

.contacts-wrapper {
  order: 1;
  margin-bottom: 10px;
  @media (min-width: 1200px) {
    order: 2;
    margin-bottom: 0;
  }
}

.contacts-title {
  font-weight: 600;
  margin-bottom: 5px;
}

.about-project-list {
  margin: 0;
  padding: 0;
  order: 2;
  width: 100%;
  @media (min-width: 1200px) {
    margin: 0 35px 0 0;
    order: 1;
    width: auto;
  }
}

.about-project-list__item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
  &:last-child {
    margin-bottom: 0;
  }
  .fas {
    font-size: 12px;
    line-height: 14px;
    color: #1c5caa;
    margin-right: 9px;
  }
  .about-project-list__link {
    font-size: 14px;
    line-height: 18px;
    color: #1c5caa;
    font-weight: 500;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

.phone-list {
  position: relative;
  margin-bottom: 10px;
  padding-left: 20px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
  }
  .fas {
    position: absolute;
    left: 0;
    top: 5px;
    font-size: 12px;
    color: #1c5caa;
    @media (min-width: 768px) {
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .phone-list__item-link {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #094fa3;
    &:hover {
      text-decoration: underline;
    }
  }

  .phone-list__item {
    position: relative;
    margin-right: 9px;
    &:before {
      @media (min-width: 768px) {
        content: '';
        position: absolute;
        right: -5px;
        top: 0;
        width: 1px;
        height: 100%;
        background-color: #094fa3;
      }
    }
    &:last-child {
      &:before {
        display: none;
      }
    }
  }
}

.email {
  display: flex;
  align-items: center;
  .fas {
    font-size: 12px;
    color: #1c5caa;
    margin-right: 8px;
  }

  .email__link {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #094fa3;
    &:hover {
      text-decoration: underline;
    }
  }
}

.footer-navigation {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-wrap: wrap;
  @media (min-width: 768px) {
    justify-content: space-between;
    width: auto;
  }

  @media (min-width: 1200px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
}

.footer {
  background-color: $white;
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: 1px solid #d7e9f2;
  @media (min-width: 768px) {
    padding-top: 30px;
    border-top: none;
  }

  .btn--vaccination {
    background-color: #fe4a49;
    border-radius: 4px;
    padding: 16px 18px;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    width: 100%;
    @media (min-width: 768px) {
      display: none;
    }
    &:hover {
      background-color: #fe4a49;
    }
    .fas {
      font-size: 16px;
      color: #ffffff;
      margin-right: 10px;
    }
  }

  .container {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-wrap: wrap;
    @media (min-width: 1200px) {
      justify-content: space-between;
      align-items: center;
    }
  }
}

.partners-list {
  order: 1;
  margin: 25px 0 0 0;
  padding: 0;
  display: flex;
  align-items: center;
  @media (min-width: 768px) {
    justify-content: center;
    width: auto;
    order: 2;
  }
  @media (min-width: 1200px) {
    width: auto;
    margin-top: 0;
  }

  .partners-list__item {
    margin-right: 18px;
    &:last-child {
      margin-right: 0;
    }
  }
}

.search-field-dropdown {
  .search-field-dropdown-history-title {
    font-size: 12px;
    line-height: 16px;
    color: #9b9b9b;
    margin-bottom: 15px;
    .fas {
      font-size: 10px;
      line-height: 11px;
      color: #9b9b9b;
      margin-right: 7px;
    }
  }

  .search-results__empty {
    text-align: center;
  }

  .search-field-dropdown-result__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    span {
      font-size: 18px;
      font-weight: 600;
      color: #555;
    }

    img {
      width: 35px;
      margin-right: 10px;
    }
  }

  .search-field-dropdown-history-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
    }
    .search-field-dropdown-history-close-button {
      background-color: transparent;
      border: none;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;
      margin-right: -4px;
      &:hover,
      &:focus,
      &:active {
        background-color: transparent;
        box-shadow: none !important;
      }
    }

    .search-field-dropdown-history-item-link {
      font-weight: 500;
      font-size: 14px;
      line-height: 26px;
      color: #5b5b5b;
    }

    .fas {
      font-size: 14px;
      line-height: 16px;
      color: #d0e5ef;
    }
  }
}

.search-field-dropdown-result {
  margin-top: -20px;
  .search-field-dropdown-result-description {
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    color: #5b5b5b;
    strong {
      color: #1c5caa;
      text-decoration: underline;
    }
  }

  .search-field-dropdown-result-all {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #1c5caa;
    width: 120px;
    flex: 0 0 auto;
    text-align: right;
  }

  .search-field-dropdown-result-item {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-bottom: 10px;
    margin-left: -20px;
    margin-right: -20px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .search-field-dropdown-result-divider {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    height: 1px;
    background-color: #d0e5f0;
  }

  .search-field-dropdown-result-content-wrapper {
    width: 100%;
  }

  .search-field-dropdown-result-item-content-title {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #979797;
    background-color: #efeff4;
    width: 100%;
    padding: 8px 15px;
    @media (min-width: 768px) {
      padding: 8px 20px;
    }
  }

  .search-field-dropdown-result-content-link {
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    color: #333333;
    display: flex;
    justify-content: space-between;
    word-break: break-word;
    &:hover {
      text-decoration: underline;
    }
    @media (max-width: 767px) {
      padding-right: 15px;
    }
  }

  .search-field-dropdown-result-content-rating {
    display: flex;
    align-items: flex-start;
    position: relative;
    z-index: 2;
    .rating-wrapper {
      margin-bottom: 0;
      @media (min-width: 768px) {
        margin-left: 25px;
      }
    }

    .rating {
      padding: 2px 5px;
      height: auto;
      min-width: auto;
      flex: 0 0 auto;
      .rating-numeral {
        font-size: 10px;
        strong {
          font-size: 14px;
        }
      }
    }
  }

  .search-field-dropdown-result-content {
    padding-left: 15px;
    padding-right: 15px;
    @media (min-width: 768px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .search-field-dropdown-result-content-item {
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
    position: relative;
    @media (max-width: 767px) {
      word-break: break-word;
      flex-wrap: wrap;
      &:before {
        content: '\f054';
        position: absolute;
        right: 0;
        top: 4px;
        font-family: 'Font Awesome 5 Free';
        color: #094fa3;
        font-weight: 900;
        font-size: 12px;
      }
    }
  }

  .search-field-dropdown-result-content-item-all {
    display: flex;
    align-items: center;
    width: 100%;
    padding-top: 10px;
    position: relative;
    background-color: #ffffff;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      right: -20px;
      top: 0;
      width: calc(100% + 20px);
      height: 1px;
      background-color: #efeff4;
    }
  }

  .search-field-dropdown-result-content-item-all-icon {
    width: 26px;
    height: 26px;
    background-color: #efeff4;
    border-radius: 4px;
    flex: 0 0 auto;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      font-size: 14px;
      color: #094fa3;
    }
  }

  .search-field-dropdown-result-content-item-link {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #1c5caa;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    &:hover {
      text-decoration: underline;
      i {
        &:before {
          display: inline-block;
        }
      }
    }
    i {
      font-size: 12px;
      margin-left: 15px;
    }
  }
}

.city-dropdown {
  display: inline-block;
  width: 100%;
  margin-bottom: 25px;
  @media (min-width: 768px) {
    width: auto;
    margin-bottom: 0;
  }
  .dropdown-btn {
    width: 100%;
    position: relative;
    background-color: transparent;
    border: 2px solid #1a7ef7;
    border-radius: 4px;
    min-width: 208px;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #1c5caa;
    text-align: left;
    padding: 10px 15px;
    &:hover {
      background-color: #fafafa;
    }
    @media (min-width: 768px) {
      width: auto;
    }
    &:active {
      background-color: transparent !important;
      color: #1c5caa !important;
    }
    &.empty {
      color: #9b9b9b;

      .fa-map-marker-alt {
        color: #9b9b9b;
      }
    }

    .fas {
      margin-right: 10px;
      font-size: 14px;
      color: #1c5caa;
    }

    .fas--arrow {
      position: absolute;
      right: 5px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  ul {
    margin-bottom: 0;
    margin-top: 0;
  }

  .dropdown-menu {
    width: 100%;
  }
}

.modal {
  .modal-header {
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    color: #000000;
    padding: 0;
    border: none;

    .close {
      width: 42px;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #e3f6ff;
      border: 1px solid #d0e5f0;
      box-sizing: border-box;
      border-radius: 5px;
      opacity: 1;
      text-shadow: none;
      right: 5px;
      top: 5px;
      margin: 0;
      span {
        font-size: 1.6rem;
        font-weight: 700;
        color: #094fa3;
        margin-top: -4px;
      }
    }
  }

  .modal-body {
    padding: 0;
    font-size: 14px;
    line-height: 22px;
    color: #7f7f7f;
    margin-bottom: 15px;
  }

  .modal-footer {
    padding: 0;
    border: none;

    .btn {
      background-color: #1a7ef7;
      border-radius: 4px;
      width: 100%;
      margin: 0;
      padding: 8px 14px;
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      color: #ffffff;
      height: 42px;
    }
  }

  .header {
    &:before {
      display: none;
    }
  }
}

.modal-backdrop {
  background-color: rgba(99, 136, 157, 0.7);
  &.show {
    opacity: 1;
  }
}

.modal-hospital-list {
  .modal-dialog {
    @media (min-width: 576px) {
      max-width: 1170px;
    }
  }

  .modal-content {
    padding: 15px 15px 20px 25px;
    width: 1170px;
    height: 290px;
    @media (max-width: 767px) {
      height: auto;
      padding-left: 15px;
      padding-bottom: 10px;
    }
  }

  .modal-header {
    display: flex;
    margin: 0 -15px 0 -25px;
    padding-bottom: 14px;
    border-bottom: 1px solid #d0e5f0;
    flex-direction: column;
    margin-right: 50px;
    @media (min-width: 992px) {
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }
    @media (max-width: 767px) {
      margin: 0;
      .modal-title {
        margin-left: 0;
        margin-right: 40px;
      }
    }
  }

  .modal-title {
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
    color: #333333;
    margin-left: 25px;
    @media (min-width: 992px) {
      font-size: 24px;
      line-height: 40px;
    }
  }

  .rating-wrapper {
    margin-bottom: 0;
    margin-left: 25px;
    .rating {
      @media (max-width: 767px) {
        height: auto;
        padding: 2px 5px;
        margin-top: 10px;
        min-width: auto;
      }
    }
  }

  .close {
    position: absolute;
    right: 20px;
    top: 15px;
    background-color: #e3f6ff;
    border: 1px solid #d0e5f0;
    box-sizing: border-box;
    border-radius: 4px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
    margin-left: auto;
    border-bottom: 1px solid #d0e5f0;
    cursor: pointer;

    .fas {
      font-size: 16px;
      color: #094fa3;
    }
  }
  .modal-body {
    margin-top: 10px;
    margin-bottom: 0;
    overflow-y: auto;

    .hospitals {
      padding: 0;

      .hospital-item {
        padding-bottom: 15px;
        display: flex;
        justify-content: flex-start;

        &:last-child {
          padding-bottom: 0;
        }

        .hospital-item__name {
          font-weight: 400;
          font-size: 16px;
          line-height: 40px;
          color: #5b5b5b;
          @media (max-width: 767px) {
            font-size: 14px;
            line-height: 18px;
          }

          &:hover {
            cursor: pointer;
            text-decoration: underline;
          }
        }
      }
    }
  }
}

.modal-indicator-list {
  .modal-dialog {
    width: 570px;
    height: 421px;
    @media (max-width: 767px) {
      width: auto;
      height: auto;
    }
  }

  .modal-content {
    padding: 15px 15px 20px 30px;
    width: 570px;
    height: 421px;
    @media (max-width: 767px) {
      width: auto;
      height: auto;
      padding-left: 15px;
    }
  }

  .modal-header {
    height: 65px;
    margin: 0 -15px 0 -30px;
    padding-bottom: 10px;
    border-bottom: 1px solid #d0e5f0;
    @media (max-width: 767px) {
      margin-left: -15px;
    }
  }

  .modal-title {
    font-weight: 700;
    font-size: 24px;
    color: #1d1b1b;
    margin-left: 30px;
    line-height: 50px;
    @media (max-width: 767px) {
      margin-left: 15px;
    }
  }

  .close {
    right: 15px;
    top: 15px;
    width: 50px;
    height: 50px;
  }

  .modal-body {
    margin-bottom: 0;
    padding: 0;

    .full_name {
      font-size: 18px;
      font-weight: 700;
      color: #1d1b1b;
      margin: 0 -15px 0 -30px;
      padding: 15px 15px 15px 30px;
      background-color: #f8f8f8;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media (max-width: 767px) {
        font-size: 14px;
        line-height: 18px;
        flex-direction: column;
        align-items: normal;
        padding-left: 15px;
        margin-left: -15px;
      }

      .rating-wrapper {
        margin-bottom: 0;
        @media (max-width: 767px) {
          margin-left: 0;
          margin-top: 15px;
          .rating {
            height: auto;
            padding: 5px 10px;
          }
        }
      }
    }

    .indicators {
      list-style: none;
      padding: 0;
      margin: 0;
      height: 245px;
      overflow-y: auto;
      @media (max-width: 767px) {
        height: auto;
      }

      li {
        padding: 10px 0 10px 0;
        display: flex;
        font-size: 14px;
        font-weight: 400;
        justify-content: space-between;
        border-bottom: 1px solid #d0e5f0;
        @media (max-width: 767px) {
          flex-direction: column;
        }

        &:last-of-type {
          border-bottom: none;
          padding-bottom: 0;
        }
      }
    }
  }
}

.modal-personal-code {
  .modal-dialog {
    width: 280px;
    min-height: 506px;
    margin: 1.75rem auto;

    @media (min-width: 768px) {
      width: 600px;
    }
  }

  .modal-content {
    padding: 10px 10px 20px 15px;
    width: 280px;
    min-height: 506px;

    @media (min-width: 768px) {
      padding: 15px 15px 20px 25px;
      width: 600px;
    }
  }

  .modal-header {
    height: 52px;
    margin: 0 -10px 0 -15px;
    padding-bottom: 10px;
    border-bottom: 1px solid #d0e5f0;

    @media (min-width: 768px) {
      height: 65px;
      margin: 0 -15px 0 -25px;
    }

    @media (max-width: 767px) {
      .close {
        top: 10px;
        right: 10px;
      }
    }
  }

  .modal-title {
    font-weight: bold;
    font-size: 16px;
    width: 157px;
    color: #1d1b1b;
    margin-left: 15px;
    height: 42px;

    @media (min-width: 768px) {
      width: 100%;
      font-size: 24px;
      line-height: 50px;
      margin-left: 25px;
    }
  }

  .close {
    right: 10px;
    top: 10px;
    width: 42px;
    height: 42px;

    @media (min-width: 768px) {
      right: 15px;
      top: 15px;
      width: 50px;
      height: 50px;
    }
  }

  .modal-body {
    margin-bottom: 0;

    .user_name {
      font-size: 14px;
      font-weight: 600;
      color: #094fa3;
      margin-top: 20px;

      @media (min-width: 768px) {
        font-size: 18px;
        margin-top: 25px;
      }
    }

    .user_phone {
      font-size: 14px;
      margin-top: 5px;
      font-weight: 500;
      color: #333333;

      @media (min-width: 768px) {
        margin-top: 10px;
      }
    }

    .user_code {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      height: 280px;
      width: 253px;

      @media (min-width: 768px) {
        width: 548px;
      }

      svg {
        height: 180px;

        @media (min-width: 768px) {
          height: 224px;
        }
      }
    }
  }

  .modal-footer {
    justify-content: center;

    .btn {
      width: 205px;
      height: 50px;
      background-color: #efeff4;
      color: #094fa3;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:hover {
        background-color: #fafafa;
      }
    }
  }
}

.modal-city {
  .modal-content {
    padding: 25px 15px 15px 15px;
    @media (min-width: 768px) {
      padding: 20px;
    }
  }

  .modal-title {
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    color: #000000;
  }

  .modal-dialog {
    width: 280px;
    margin-left: auto;
    margin-right: auto;
    @media (min-width: 768px) {
      width: 100%;
      max-width: 330px;
    }
  }

  .close {
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e3f6ff;
    border: 1px solid #d0e5f0;
    box-sizing: border-box;
    border-radius: 5px;
    opacity: 1;
    text-shadow: none;
    right: 5px;
    top: 5px;
    margin: 0;
    cursor: pointer;
    span {
      font-size: 1.6rem;
      font-weight: 700;
      color: #094fa3;
      margin-top: -4px;
    }
  }

  .modal-header,
  .modal-footer {
    padding: 0;
    border: none;
  }

  .modal-header {
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    color: #000000;
  }

  .modal-body {
    padding: 0;
    font-size: 14px;
    line-height: 22px;
    color: #7f7f7f;
    margin-bottom: 15px;
  }

  .modal-footer {
    .btn {
      background-color: #1a7ef7;
      border-radius: 4px;
      width: 100%;
      margin: 0;
      padding: 8px 14px;
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      color: #ffffff;
      height: 42px;

      &[disabled] {
        background-color: #efeff4;
        border-color: #efeff4;
      }
    }
  }

  .city-dropdown {
    display: flex;
    margin-top: 15px;
    margin-bottom: 0;
    @media (min-width: 768px) {
      margin-top: 20px;
    }
    .dropdown-btn {
      min-width: 100%;
      width: 100%;
      &:active {
        color: #9b9b9b !important;
      }
    }
  }

  .dropdown-menu {
    border: 2px solid #1a7ef7;
  }
}

.modal-confirm {
  .modal-title {
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    padding-right: 50px;
    @media (min-width: 768px) {
      font-size: 1.5rem;
    }
  }

  .modal-content {
    padding: 15px 15px 20px 15px;
    @media (min-width: 768px) {
      padding: 25px;
    }
  }

  .modal-dialog {
    width: 280px;
    margin-left: auto;
    margin-right: auto;
    @media (min-width: 768px) {
      width: 100%;
      max-width: 600px;
    }
  }

  .modal-header {
    position: relative;
    padding-bottom: 25px;
    &:before {
      content: '';
      position: absolute;
      left: -15px;
      bottom: 0;
      width: calc(100% + 30px);
      height: 1px;
      background-color: #d0e5f0;
      @media (min-width: 768px) {
        left: -25px;
        bottom: 0;
        width: calc(100% + 50px);
      }
    }
  }

  .close {
    right: -5px;
    top: -5px;
    @media (min-width: 768px) {
      right: -15px;
      top: -15px;
    }
  }

  .modal-confirm-field__label {
    font-size: 14px;
    line-height: 18px;
    color: #696666;
    margin-bottom: 5px;
    @media (min-width: 768px) {
      width: 200px;
      padding-right: 14px;
      margin-bottom: 0;
    }
  }

  .modal-confirm-field__label-description {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #333333;
    strong {
      font-weight: bold;
      color: #1a7ef7;
    }
  }

  .modal-confirm-field {
    padding-bottom: 15px;
    border-bottom: 1px solid #d0e5f0;
    margin-top: 15px;
    padding-right: 15px;
    margin-right: -15px;
    &:first-child {
      margin-top: 0;
    }
    @media (min-width: 768px) {
      display: flex;
      align-items: baseline;
      border-bottom: none;
      margin-right: 0;
      padding-right: 0;
    }
  }

  .modal-confirm-field-divider {
    @media (min-width: 768px) {
      margin-bottom: 15px;
      width: 100%;
      height: 1px;
      background-color: #d0e5f0;
    }
  }

  .modal-confirm-alert {
    background-color: #fff0db;
    border: 1px solid #f6c38b;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 15px;
    font-size: 14px;
    line-height: 20px;
    color: #dc8d29;
    margin-bottom: 15px;
  }
}

.modal-confirm-field__accordion {
  margin-left: -15px;
  margin-right: -15px;
  .modal-confirm-field__accordion-dropdown {
    background-color: #efeff4;
    padding: 8px 15px;
    text-align: left;
    font-size: 14px;
    line-height: 18px;
    font-weight: bold;
    color: #094fa3;
    width: 100%;
    .fas {
      font-size: 12px;
      margin-left: 10px;
    }
  }

  .modal-confirm-field {
    padding-right: 0;
    margin-right: 0;
    margin-left: 15px;
    &:first-child {
      margin-top: 10px;
    }
  }

  .modal-confirm-field__accordion-head {
    @media (min-width: 768px) {
      display: none;
    }
  }

  .collapse {
    @media (min-width: 768px) {
      display: block;
    }
  }
}

.modal-confirm-form-phone {
  .modal-confirm-form-phone-title {
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    color: #333333;
    margin-bottom: 20px;
    @media (min-width: 768px) {
      font-size: 16px;
      line-height: 25px;
      margin-bottom: 15px;
    }
  }

  .modal-confirm-form-phone-number {
    margin-bottom: 15px;
    @media (min-width: 768px) {
      display: flex;
      align-items: center;
    }
    input {
      font-size: 16px;
      line-height: 21px;
      color: #094fa3;
      background-color: #ffffff;
      border: 1px solid #cccccc;
      border-radius: 4px;
      padding: 14px 12px;
      margin-bottom: 0;
      letter-spacing: 1px;
      font-weight: 500;
      width: 100%;
      @media (min-width: 768px) {
        margin-right: 10px;
        width: auto;
      }
      &::placeholder {
        font-size: 16px;
        line-height: 21px;
        color: #094fa3;
      }
      &:focus {
        outline: none;
      }
    }
    &.error {
      input {
        border-color: #ff4040;
      }
    }

    .modal-confirm-form-phone-number-error {
      font-size: 12px;
      line-height: 16px;
      color: #ff4040;
      margin-top: 10px;
      @media (min-width: 768px) {
        margin-top: 0;
      }
    }
  }
}

.modal-confirm-form-checkbox {
  margin-bottom: 5px;
  .checkbox {
    input {
      position: absolute;
      z-index: -1;
      opacity: 0;
      margin: 10px 0 0 20px;
      &:checked {
        + {
          .checkbox__description {
            &:before {
              opacity: 1;
            }
            &:after {
              background-color: #1a7ef7;
              border-color: #1a7ef7;
            }
          }
        }
      }
    }
  }

  .checkbox__description {
    position: relative;
    padding: 0 0 0 40px;
    cursor: pointer;
    font-size: 12px;
    line-height: 16px;
    color: #8e8e8e;
    max-width: 320px;
    @media (min-width: 768px) {
      font-size: 14px;
      line-height: 18px;
    }
    &:before {
      content: url('../../assets/img/next/arrow.svg');
      position: absolute;
      top: 7px;
      left: 6px;
      transition: 0.2s;
      opacity: 0;
      z-index: 2;
    }
    &:after {
      content: '';
      width: 30px;
      height: 30px;
      position: absolute;
      top: 3px;
      left: 0;
      background: #fff;
      border: 1px solid #7f7f7f;
      border-radius: 4px;
      transition: 0.2s;
    }
  }
}

.modal-confirm-form-confirm-phone {
  margin-top: 10px;
  .modal-confirm-form-confirm-phone-title {
    font-size: 14px;
    line-height: 22px;
    color: #333333;
    @media (min-width: 768px) {
      font-size: 18px;
      line-height: 25px;
    }
    strong {
      color: #094fa3;
      display: inline-block;
    }
  }

  .modal-confirm-form-confirm-phone-code {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    margin-top: 25px;
    @media (min-width: 768px) {
      margin-bottom: 30px;
      margin-top: 30px;
    }
    input {
      width: 50px;
      height: 70px;
      margin-bottom: 0;
      margin-right: 15px;
      background-color: #ffffff;
      border: 1px solid #cccccc;
      box-sizing: border-box;
      border-radius: 4px;
      font-weight: bold;
      font-size: 24px;
      line-height: 25px;
      color: #094fa3;
      text-align: center;
      @media (min-width: 768px) {
        margin-right: 20px;
      }
      &:focus {
        outline: none;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.modal-confirm-form-success {
  font-size: 14px;
  line-height: 25px;
  color: #333333;
  @media (min-width: 768px) {
    font-size: 16px;
  }
  strong {
    &:first-of-type {
      display: block;
      @media (min-width: 768px) {
        margin-bottom: 12px;
      }
    }
  }
}

.modal-confirm-form-confirm-checkbox {
  .modal-confirm-form-confirm-checkbox-title {
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    color: #333333;
    @media (min-width: 768px) {
      font-size: 16px;
      line-height: 25px;
    }
  }

  .modal-confirm-form-confirm-checkbox-fields {
    background-color: #ffffff;
    border-top: 1px solid #d0e5f0;
    border-bottom: 1px solid #d0e5f0;
    box-sizing: border-box;
    border-radius: 4px;
    margin-top: 15px;
    margin-bottom: 30px;
    margin-left: -15px;
    margin-right: -15px;
    @media (min-width: 768px) {
      margin-left: 0;
      margin-right: 0;
      border: 1px solid #d0e5f0;
    }
  }

  .modal-confirm-form-confirm-checkbox-field {
    border-bottom: 1px solid #d0e5f0;
    display: flex;
    align-items: center;
    &:last-child {
      border-bottom: none;
    }
    label {
      position: relative;
      cursor: pointer;
      font-weight: 500;
      font-size: 14px;
      line-height: 25px;
      color: #5b5b5b;
      margin-bottom: 0;
      padding: 14px;
      width: 100%;
      @media (min-width: 768px) {
        font-size: 16px;
        line-height: 25px;
      }
      &:before {
        content: '';
        background-color: transparent;
        border: 1px solid #d0e5f0;
        padding: 11px;
        display: inline-block;
        position: relative;
        vertical-align: middle;
        border-radius: 100%;
        cursor: pointer;
        margin-right: 12px;
        @media (min-width: 768px) {
          padding: 14px;
        }
      }
    }
    input {
      padding: 0;
      height: initial;
      width: initial;
      margin-bottom: 0;
      display: none;
      cursor: pointer;
      &:checked {
        + {
          label {
            background-color: #e3f6ff;
            &:before {
              background-color: #1a7ef7;
            }
            &:after {
              content: url('../../assets/img/next/checkbox-arrow.svg');
              display: block;
              position: absolute;
              top: 17px;
              left: 18px;
              @media (min-width: 768px) {
                top: 17px;
                left: 20.5px;
              }
            }
          }
        }
      }
    }
  }
}

.breadcrumbs-wrapper {
  height: 38px;
  border-top: 1px solid #d0e5f0;
  border-bottom: 1px solid #d0e5f0;
  margin-top: 20px;

  .breadcrumbs-item {
    height: 100%;
    margin: 0 auto;
    padding: 0 15px;
    display: flex;
    align-items: center;

    &.hospital-page,
    &.doctor-page {
      @media (min-width: 1200px) {
        max-width: 1200px;
      }
    }
  }

  .breadcrumbs-link {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #1c5caa;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }

    .fas {
      font-size: 14px;
      color: #094fa3;
      margin-right: 8px;
    }
  }
}

.user-cabinet-wrapper {
  padding-top: 25px;
  padding-bottom: 85px;
  background-color: #edf4f8;
  @media (max-width: 767px) {
    padding-bottom: 20px;
  }

  .user-cabinet-navigation__item-description {
    font-weight: 600;
    font-size: 16px !important;
    line-height: 21px !important;
    color: #1d1b1b !important;
    @media (max-width: 767px) {
      font-size: 14px !important;
      line-height: 18px !important;
      color: #696666 !important;
    }
  }
}

.user-cabinet-navigation {
  .user-cabinet-header-row {
    margin: 0;

    @media (max-width: 992px) {
      margin-bottom: 10px;
    }
  }

  .user-cabinet-wrapper {
    display: flex;
    flex-wrap: wrap;
  }
  .user-cabinet-navigation-title {
    font-weight: bold;
    font-size: 18px;
    line-height: 50px;
    color: #1d1b1b;
    margin-bottom: 15px;
    height: 50px;
    @media (min-width: 992px) {
      height: 60px;
      line-height: 60px;
      font-size: 24px;
      margin-bottom: 35px;
    }

    @media (max-width: 767px) {
      margin-bottom: -10px;
      margin-top: 10px;
      text-align: left;
    }
  }

  .user-cabinet-navigation__link {
    &:hover {
      text-decoration: none;
    }
    &.active {
      @media (max-width: 767px) {
        .user-cabinet-navigation__item {
          height: 50px;
          .user-cabinet-navigation__item-description {
            font-size: 14px !important;
            line-height: 18px !important;
          }

          .user-cabinet-navigation__item-description-icon {
            img {
              width: 28px;
              height: 28px;
            }
          }
        }
      }
    }
  }

  .user-cabinet-navigation__item {
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0px 2px 4px rgba(9, 79, 163, 0.15);
    border-radius: 5px;
    height: 60px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    text-decoration: none;
    transition: all 0.15s ease-in-out;
    &:hover {
      box-shadow: 2px 4px 10px rgba(9, 79, 163, 0.15);
    }
    @media (max-width: 767px) {
      padding: 0 10px;
      height: 50px;
    }

    .dropdown-menu {
      width: 100%;
      padding: 0;

      .user-cabinet-navigation__item-description {
        padding: 5px 15px 5px 5px;
        white-space: normal;
        @media (max-width: 767px) {
          font-size: 12px !important;
          line-height: 16px !important;
        }
        &:focus {
          background-color: transparent;
        }
      }
    }

    .single {
      cursor: default;

      &::after {
        border: 0;
      }
    }

    &.disabled {
      opacity: 0.4;
      cursor: default;
    }
    &.dropdown {
      margin: 0;
      padding: 0;
      height: auto;

      @media (max-width: 768px) {
        margin-bottom: 5px;
      }

      button {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        font-size: 14px;
        font-weight: 600;
        padding: 5px 15px 5px 5px;
        background-color: #ffffff;
        border: none;
        @media (max-width: 767px) {
          padding-right: 10px;
        }
        &:focus {
          background-color: #ffffff;
        }
        &:after {
          display: none;
        }
        &:hover {
          box-shadow: 2px 4px 10px rgba(9, 79, 163, 0.15);
        }
        .user-cabinet-navigation__item-description-icon {
          background-color: #1a7ef7;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all 0.15s ease-in-out;
          img {
            width: 28px;
            height: 28px;
            @media (max-width: 767px) {
              width: 22px;
              height: 22px;
            }
          }
        }

        .user-cabinet-navigation__item-description-content {
          font-weight: 600;
          font-size: 12px;
          line-height: 18px;
          color: #1c5caa;
          text-align: left;
          white-space: nowrap;
          overflow-x: hidden;
          position: relative;
          &:before {
            content: '';
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            width: 30px;
            background-image: linear-gradient(270deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
          }
          @media (min-width: 768px) {
            font-size: 14px;
            white-space: normal;
            overflow-x: inherit;
            &:before {
              display: none;
            }
          }
        }

        .user-cabinet-navigation__item-description-content-number {
          font-weight: normal;
          text-align: left;
          color: #979797;
          margin-top: 5px;
          @media (max-width: 767px) {
            font-size: 12px;
          }
        }

        .user-cabinet-navigation__item-description-icon-dropdown {
          margin-left: auto;
          position: relative;
          padding-left: 14px;
          @media (max-width: 767px) {
            padding-left: 10px;
            i {
              font-size: 12px !important;
            }
          }
          &:before {
            content: '';
            position: absolute;
            left: 0px;
            top: -14px;
            width: 1px;
            height: 50px;
            background-color: #d0e5f0;
          }
          i {
            color: #094fa3;
            font-size: 16px;
          }
        }
      }
    }

    &.active {
      background-color: #1a7ef7;
      .user-cabinet-navigation__item-description {
        color: #ffffff;
        font-size: 14px;
        @media (min-width: 992px) {
          font-size: 16px;
        }
      }
      .fas {
        color: #ffffff;
      }
    }

    &.pointer:hover {
      cursor: pointer;
    }
  }

  .user-cabinet-navigation__item-description {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #979797;
    .user-cabinet-navigation__item-description-arrow {
      margin-left: auto;
      i {
        transform: none !important;
        color: #979797;
        font-size: 12px;
        @media (min-width: 768px) {
          font-size: 14px;
        }
      }
    }
  }

  .user-cabinet-navigation__item-description-icon {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
    margin-right: 10px;
    background-color: #ffffff;
    border-radius: 4px;
    transition: all 0.15s ease-in-out;
    svg {
      width: 28px;
      height: 28px;
    }
    @media (max-width: 767px) {
      width: 40px !important;
      height: 40px !important;
      svg,
      img {
        width: 30px;
        height: 30px;
      }
    }
  }

  .user-cabinet-navigation__item-status {
    margin-left: auto;
    .fas {
      font-size: 18px;
    }
  }
}

.streaming-records-wrapper {
  margin-top: 25px;
  @media (min-width: 768px) {
    margin-top: 35px;
  }
  .streaming-records-title {
    position: relative;
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    color: #696666;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 767px) {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
    }
  }

  .streaming-records-item {
    background-color: #ffffff;
    box-shadow: 0px 2px 10px rgba(9, 79, 163, 0.1);
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 15px;
    @media (min-width: 768px) {
      padding: 25px 25px 10px 25px;
      display: flex;
    }
  }
}

.streaming-records-item__user {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  @media (min-width: 768px) {
    margin-bottom: 25px;
  }

  @media (max-width: 767px) {
    align-items: flex-start;
  }

  .streaming-records-item__user-icon {
    width: 60px;
    height: 60px;
    background-color: #c4c4c4;
    border-radius: 5px;
    background-size: cover;
    background-position: top;
    margin-right: 10px;
    flex: 0 0 auto;
    @media (min-width: 768px) {
      width: 70px;
      height: 70px;
      margin-right: 15px;
    }
  }

  .streaming-records-item__user-title {
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    color: #000000;
    margin-bottom: 5px;
    text-decoration: none;
    @media (min-width: 768px) {
      font-size: 18px;
      line-height: 23px;
      margin-bottom: 15px;
    }
  }

  .streaming-records-item__user-description {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    color: #979797;
    @media (min-width: 768px) {
      font-size: 14px;
      line-height: 18px;
    }
  }
}

.streaming-records-item__address-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
  @media (min-width: 768px) {
    display: flex;
    flex-wrap: wrap;
  }
}

.streaming-records-item__address-list-item {
  margin-bottom: 15px;
  @media (min-width: 768px) {
    margin-bottom: 25px;
    width: 60%;
  }

  &:nth-child(2n) {
    @media (min-width: 768px) {
      width: 40%;
    }
  }
  .streaming-records-item__address-list-item-title {
    font-size: 14px;
    line-height: 18px;
    color: #696666;
    margin-bottom: 8px;
  }

  .streaming-records-item__address-list-item-description {
    font-size: 14px;
    line-height: 18px;
    color: #333333;
    font-weight: 500;
    a {
      text-decoration-line: underline;
      color: #094fa3;
    }
  }
}

.streaming-records-item__period {
  width: 100%;
  flex: 0 0 auto;
  @media (min-width: 768px) {
    max-width: 200px;
    margin-left: auto;
  }

  .streaming-records-item__period-date {
    background-color: #efeff4;
    border-radius: 4px;
    font-size: 16px;
    line-height: 21px;
    color: #333333;
    padding: 15px 25px;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-bottom: 10px;
    @media (min-width: 768px) {
      flex-direction: column;
      padding: 15px;
      background-color: #e3f6ff;
    }
    strong {
      position: relative;
      font-size: 24px;
      line-height: 31px;
      color: #094fa3;
      margin-right: 25px;
      padding-right: 25px;
      &:before {
        content: '';
        position: absolute;
        right: 0;
        top: -4px;
        width: 1px;
        height: 40px;
        background-color: #d0e5f0;
        @media (min-width: 768px) {
          display: none;
        }
      }

      @media (min-width: 768px) {
        margin-bottom: 5px;
        margin-right: 0;
        padding-right: 0;
      }
    }
  }

  .streaming-records-item__period-action {
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e3f6ff;
    border: 2px solid #d0e5f0;
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: #094fa3;
    width: 100%;
    &:focus,
    &:active {
      background-color: #e3f6ff !important;
      outline: none !important;
      border-color: #d0e5f0 !important;
      color: #094fa3 !important;
      box-shadow: none !important;
    }
    .fas {
      font-size: 12px;
      margin-right: 5px;
      color: #1c5caa;
    }
  }
}

.streaming-records-current {
  &.disabled {
    margin-top: 30px;
    .streaming-records-item__information,
    .streaming-records-item__period-date,
    .streaming-records-item__period-action {
      filter: grayscale(100%);
    }

    .streaming-records-item__user-title,
    .streaming-records-item__address-list-item-description,
    .streaming-records-item__address-list-item-description strong,
    .streaming-records-item__period-date,
    .streaming-records-item__period-date strong,
    .streaming-records-item__period-action {
      color: #979797;
    }

    .streaming-records-item__period-action {
      &.visited {
        filter: grayscale(0);
        color: #094fa3;
      }
    }
  }

  button {
    display: block;
    margin: 0 auto;
    // color: white;
  }
}

.personal-data {
  margin-top: 25px;
  .personal-data-title {
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    color: #1d1b1b;
    margin-bottom: 15px;
    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 21px;
    }
  }

  .personal-data-list__item-feature {
    font-size: 14px;
    line-height: 18px;
    color: #696666;
    margin-bottom: 5px;
    @media (min-width: 768px) {
      width: 45%;
      margin-bottom: 0;
    }
  }

  .personal-data-list__item-description {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #333333;
    @media (min-width: 768px) {
      width: 55%;
    }

    span {
      display: block;
      font-size: 12px;
      line-height: 20px;
      color: #979797;
      &:first-child {
        margin-top: 5px;
      }
    }

    .id-card-series {
      margin-top: 15px;
      &:first-of-type {
        margin-top: 0;
      }
    }
  }

  .personal-data-list {
    padding: 0;
    margin: 0;
    list-style-type: none;
    background-color: #ffffff;
    box-shadow: 0px 2px 10px rgba(9, 79, 163, 0.1);
    border-radius: 5px;
  }

  .personal-data-list__item {
    position: relative;
    padding: 15px;
    &:last-child {
      &:before {
        display: none;
      }
    }

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 15px;
      width: calc(100% - 30px);
      height: 1px;
      background-color: #d0e5f0;
      @media (min-width: 768px) {
        display: none;
      }
    }

    @media (min-width: 768px) {
      display: flex;
      padding: 20px 25px 15px 25px;
    }
    &:nth-child(2n) {
      @media (min-width: 768px) {
        background-color: #f8f8f8;
      }
    }
  }
}

.personal-indicators {
  margin-top: 30px;
  &:first-child {
    margin-top: 25px;
  }
  .personal-indicators-title {
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    color: #1d1b1b;
    margin-bottom: 15px;
    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 21px;
    }
  }

  .personal-indicators-subtitle {
    font-size: 14px;
    line-height: 18px;
    color: #7f7f7f;
    margin-top: -5px;
    margin-bottom: 15px;
    @media (max-width: 767px) {
      margin-bottom: 30px;
    }
    a {
      margin-left: 5px;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      text-decoration-line: underline;
      color: #094fa3;
      &:hover {
        text-decoration: none;
      }
    }
    .fas {
      font-size: 12px;
      color: #094fa3;
      margin-left: 5px;
    }
  }

  .personal-indicators-list__item-feature {
    font-size: 14px;
    line-height: 18px;
    color: #696666;
    margin-bottom: 5px;
    @media (min-width: 768px) {
      width: 45%;
      margin-bottom: 0;
    }
  }

  .personal-indicators-list__item-description {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #333333;
    @media (min-width: 768px) {
      width: 55%;
    }

    .fas {
      font-size: 16px;
      color: #1a7ef7;
      margin-left: 14px;
    }
    .personal-indicators-list__item-description-date {
      font-size: 14px;
      line-height: 18px;
      color: #979797;
      .fas {
        font-size: 12px;
        color: #979797;
        margin-left: 12px;
        margin-right: 5px;
      }
    }
  }

  .personal-indicators-list {
    padding: 0;
    margin: 0;
    list-style-type: none;
    background-color: #ffffff;
    box-shadow: 0px 2px 10px rgba(9, 79, 163, 0.1);
    border-radius: 5px;
    overflow: hidden;
  }

  .personal-indicators-list__item {
    padding: 15px;
    position: relative;
    &:last-child {
      &:before {
        display: none;
      }
    }

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 15px;
      width: calc(100% - 30px);
      height: 1px;
      background-color: #d0e5f0;
      @media (min-width: 768px) {
        display: none;
      }
    }

    @media (min-width: 768px) {
      display: flex;
      padding: 20px 25px 15px 25px;
    }

    &:nth-child(2n + 1) {
      @media (min-width: 768px) {
        background-color: #f8f8f8;
      }
    }
  }
}

.personal-medic {
  margin-top: 30px;
  .personal-medic-list-item__data {
    display: flex;
    align-items: center;

    .personal-medic-list-item__data-avatar {
      width: 70px;
      height: 70px;
      flex: 0 0 auto;
      background-position: top;
      background-size: cover;
      margin-right: 10px;
      border-radius: 4px;
      @media (min-width: 768px) {
        width: 90px;
        height: 90px;
        margin-right: 30px;
        margin-left: 5px;
        margin-bottom: 0;
      }
    }

    .personal-medic-list-item__data-about-title {
      font-weight: bold;
      font-size: 16px;
      line-height: 21px;
      color: #1d1b1b;
      margin-bottom: 5px;
      @media (min-width: 768px) {
        font-size: 18px;
        line-height: 23px;
        margin-bottom: 10px;
      }
    }

    .personal-medic-list-item__data-about-subtitle {
      font-weight: bold;
      font-size: 12px;
      line-height: 16px;
      color: #979797;
      text-align: left;

      @media (min-width: 768px) {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }

  .personal-medic-list {
    padding: 0;
    margin: 0;
    list-style-type: none;
    background-color: #ffffff;
    box-shadow: 0px 2px 10px rgba(9, 79, 163, 0.1);
    border-radius: 5px;
    overflow: hidden;
  }

  .personal-medic-list__item {
    padding: 15px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    &:first-child {
      @media (max-width: 767px) {
        background-color: #f8f8f8;
        .personal-medic-list-item__data {
          flex-direction: column;
        }

        .personal-medic-list-item__data-avatar {
          margin-right: 0;
          margin-bottom: 10px;
        }

        .personal-medic-list-item__data-about,
        .personal-medic-list-item__data-about-subtitle {
          text-align: center;
        }
      }
    }

    &:last-child {
      &:before {
        display: none;
      }
    }

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 15px;
      width: calc(100% - 30px);
      height: 1px;
      background-color: #d0e5f0;
      @media (min-width: 768px) {
        display: none;
      }
    }

    @media (min-width: 768px) {
      justify-content: space-between;
      padding: 20px 25px 15px 25px;
      flex-direction: row;
    }

    &:nth-child(2n + 1) {
      @media (min-width: 768px) {
        background-color: #f8f8f8;
      }
    }
  }

  .personal-medic-list__item-feature {
    font-size: 14px;
    line-height: 18px;
    color: #696666;
    margin-bottom: 5px;
    @media (min-width: 768px) {
      width: 45%;
      margin-bottom: 0;
    }
  }

  .personal-medic-list__item-description {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #333333;
    @media (min-width: 768px) {
      width: 55%;
    }

    a {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      text-decoration-line: underline;
      color: #094fa3;
    }
  }

  .personal-medic-list__item-button {
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;
    min-width: 100%;
    padding: 16px 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    @media (min-width: 768px) {
      min-width: 228px;
    }
    i {
      color: #ffffff;
      font-size: 12px;
      margin-right: 10px;
    }
  }

  .personal-medic-title {
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    color: #1d1b1b;
    margin-bottom: 15px;
    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 21px;
    }
  }

  .personal-medic-list--workspace {
    .personal-medic-list__item {
      background-color: #ffffff;
      @media (max-width: 767px) {
        margin-bottom: -20px;
      }
    }

    .personal-medic-list__item-feature {
      position: relative;
      margin-bottom: 15px;
      padding-bottom: 5px;
      width: 100%;
      @media (min-width: 768px) {
        margin-bottom: 0;
        padding-bottom: 0;
      }

      a {
        &:hover {
          text-decoration: underline;
          i {
            &:before {
              display: inline-block;
            }
          }
        }
      }

      &:last-child {
        &:before {
          display: none;
        }
      }

      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: #d0e5f0;
        @media (min-width: 768px) {
          display: none;
        }
      }

      .personal-medic-list__item-feature__institution {
        font-size: 16px;
        line-height: 21px;
        color: #1d1b1b;
        display: block;
        margin-bottom: 10px;
        font-weight: 700;
      }

      .personal-medic-list__item-feature__address {
        font-size: 14px;
        line-height: 22px;
        color: #094fa3;
        margin-bottom: 10px;
        font-weight: 500;
        @media (max-width: 767px) {
          font-size: 12px;
          i {
            font-size: 14px;
          }
        }

        .fas {
          margin-right: 10px;
        }
      }
    }

    .personal-medic-list__item-feature,
    .personal-medic-list__item-description {
      strong {
        font-size: 14px;
        line-height: 18px;
        color: #979797;
        display: block;
        margin-bottom: 10px;
        font-weight: 500;
        @media (max-width: 767px) {
          font-size: 12px;
        }
        &:first-child {
          font-size: 16px;
          line-height: 21px;
          font-weight: 700;
          color: #333333;
        }
      }
      a {
        font-size: 14px;
        line-height: 22px;
        color: #094fa3;
        margin-bottom: 10px;
        font-weight: 500;
        text-decoration: none;
        display: flex;
        align-items: baseline;
        @media (max-width: 767px) {
          font-size: 12px;
          i {
            font-size: 14px;
          }
        }
        i {
          margin-right: 10px;
        }
      }
      span {
        display: flex;
        align-items: baseline;
        font-size: 14px;
        line-height: 18px;
        color: #094fa3;
        margin-bottom: 10px;
        font-weight: 500;
        text-decoration: underline;
        i {
          margin-right: 10px;
          &:before {
            display: inline-block;
          }
        }
      }
    }
  }
}

.personal-review-empty {
  padding: 25px;
  text-align: center;
  color: #979797;
}

.personal-review {
  margin-top: 25px;
  .personal-review-title {
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 15px;
    color: #000000;
    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 21px;
    }
  }

  .personal-review-list {
    padding: 0;
    margin: 0;
    list-style-type: none;
    background-color: #ffffff;
    box-shadow: 0px 2px 10px rgba(9, 79, 163, 0.1);
    border-radius: 5px;
    @media (max-width: 767px) {
      padding-top: 15px;
    }
  }

  .personal-review-list__item {
    display: flex;
    &:nth-child(2n) {
      @media (min-width: 768px) {
        background-color: #f8f8f8;
      }
    }
  }

  .personal-review-list__item-date,
  .personal-review-list__item-boolean,
  .personal-review-list__item-description {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #333333;
    padding: 15px 15px 15px 20px;
  }

  .personal-review-list__item-date {
    padding: 15px 15px 15px 25px;
    width: 20%;
    border-right: 1px solid #d0e5f0;
  }

  .personal-review-list__item-boolean {
    width: 20%;
    border-right: 1px solid #d0e5f0;
  }

  .personal-review-list__item-description {
    width: 60%;
  }

  .personal-review-list__item {
    display: none;
    @media (min-width: 768px) {
      display: flex;
    }

    &:first-child {
      .personal-review-list__item-date,
      .personal-review-list__item-boolean,
      .personal-review-list__item-description {
        font-weight: normal;
        color: #979797;
        padding: 25px 25px 10px 20px;
      }
      .personal-review-list__item-date {
        padding: 25px 25px 10px 25px;
      }
    }
  }

  .personal-review-list__item-mobile {
    position: relative;
    display: block;
    padding: 0 15px 30px 15px;
    @media (min-width: 768px) {
      display: none;
    }

    &:last-child {
      &:before {
        display: none;
      }
    }

    &:before {
      content: '';
      position: absolute;
      bottom: 15px;
      left: 15px;
      width: calc(100% - 30px);
      height: 1px;
      background-color: #d0e5f0;
      @media (min-width: 768px) {
        display: none;
      }
    }

    .personal-review-list__item-date {
      padding: 0 !important;
      border-right: none;
      width: 100%;
      margin-bottom: 15px;
      font-weight: normal;
      .far {
        font-size: 14px;
        color: #696666;
        margin-right: 5px;
      }
    }
    .personal-review-list__item-boolean,
    .personal-review-list__item-description {
      width: 100%;
      padding: 0 !important;
      border-right: none;
      font-weight: normal;
      strong {
        display: block;
        width: 100%;
        font-weight: 500;
        color: #333333;
        margin-top: 5px;
      }
    }

    .personal-review-list__item-boolean {
      margin-bottom: 15px;
    }
  }
}

.personal-vaccination {
  .personal-vaccination-list {
    padding: 0;
    margin: 0;
    list-style-type: none;
    margin-top: 25px;
  }

  .personal-vaccination-list__item-name {
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    color: #333333;
    border-right: 1px solid #d0e5f0;
    width: 35%;
    padding: 20px;
  }

  .personal-vaccination-list__item-data {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #696666;
    border-right: 1px solid #d0e5f0;
    width: 17.5%;
    padding: 20px;
    display: flex;
    align-items: flex-start;
    .fas {
      position: relative;
      color: #696666;
      font-size: 12px;
      margin-right: 7px;
    }
  }

  .personal-vaccination-list__item-boolean {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #696666;
    border-right: 1px solid #d0e5f0;
    width: 17.5%;
    padding: 20px;
  }

  .personal-vaccination-list__item-description {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #333333;
    width: 45%;
    padding: 20px;
  }

  .personal-vaccination-list__item {
    display: none;
    @media (min-width: 768px) {
      display: flex;
    }
    &:nth-child(2n) {
      @media (min-width: 768px) {
        background-color: #f8f8f8;
      }
    }
    &:first-child {
      .personal-vaccination-list__item-name,
      .personal-vaccination-list__item-data,
      .personal-vaccination-list__item-boolean,
      .personal-vaccination-list__item-description {
        font-size: 14px;
        line-height: 18px;
        font-weight: normal;
        color: #696666;
      }
    }
  }

  .personal-vaccination-list {
    background-color: #ffffff;
    box-shadow: 0px 2px 10px rgba(9, 79, 163, 0.1);
    border-radius: 5px;
    padding-top: 15px;
  }

  .personal-vaccination-list__item-mobile {
    position: relative;
    display: block;
    padding: 0 15px 30px 15px;
    @media (min-width: 768px) {
      display: none;
    }
    &:last-child {
      &:before {
        display: none;
      }
    }

    &:before {
      content: '';
      position: absolute;
      bottom: 15px;
      left: 15px;
      width: calc(100% - 30px);
      height: 1px;
      background-color: #d0e5f0;
      @media (min-width: 768px) {
        display: none;
      }
    }

    .personal-vaccination-list__item-data {
      padding: 0;
      border-right: none;
      width: 100%;
      margin-bottom: 15px;
      font-weight: 500;
      .far {
        font-size: 14px;
        color: #696666;
        margin-right: 5px;
      }
    }

    .personal-vaccination-list__item-name,
    .personal-vaccination-list__item-boolean,
    .personal-vaccination-list__item-description {
      padding: 0;
      border-right: none;
      width: 100%;
      margin-bottom: 15px;
      font-weight: normal;
      strong {
        display: block;
        font-weight: 500;
        color: #333333;
        margin-top: 5px;
      }
    }

    .personal-vaccination-list__item-name {
      @media (max-width: 767px) {
        strong {
          font-weight: 700;
        }
      }
    }

    .personal-vaccination-list__item-description {
      margin-bottom: 0;
    }
  }
}

.personal-visits-wrapper {
  &.extended {
    .personal-visits-list__item-button-icon {
      margin-right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (min-width: 768px) {
        margin-right: 28px;
      }
      img {
        width: 15px;
        height: auto;
        @media (min-width: 768px) {
          width: 18px;
        }
      }
    }

    .personal-visits-list__item {
      flex-wrap: wrap;
    }

    .personal-visits-list__item-about {
      flex: 1;
    }
  }
}

.personal-visits {
  .personal-visits-list {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  .personal-visits-list__item {
    padding: 15px;
    background-color: #ffffff;
    box-shadow: 0px 2px 10px rgba(9, 79, 163, 0.1);
    border-radius: 5px;
    margin-top: 15px;
    @media (min-width: 768px) {
      display: flex;
      align-items: flex-start;
      padding: 25px;
    }

    &:first-child {
      margin-top: 25px;
    }

    .buy {
      width: 100%;
      height: 50px;
      font-weight: 700;
      font-size: 14px;
      line-height: 33px;
      margin-top: 25px;

      .fas {
        margin-right: 15px;
      }
    }
  }

  .personal-visits-list__item-about {
    width: 100%;
    @media (min-width: 768px) {
      padding-right: 60px;
    }
  }

  .personal-visits-list__item-data {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #696666;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    .fas {
      font-size: 12px;
      color: #696666;
      margin-right: 7px;
    }
  }

  .personal-visits-list__item-link {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #1a7ef7;
    margin-bottom: 15px;
    display: block;
    &:hover {
      text-decoration: underline;
    }
    @media (max-width: 767px) {
      font-weight: bold;
      font-size: 14px;
      line-height: 22px;
      color: #094fa3;
    }
  }

  .personal-visits-list__item-medic {
    margin-bottom: 15px;
    .personal-visits-list__item-medic-title {
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      color: #1d1b1b;
      margin-bottom: 5px;
    }

    .personal-visits-list__item-medic-description {
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      color: #979797;
    }
  }

  .personal-visits-list__item-diagnosis {
    .personal-visits-list__item-diagnosis-title {
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      color: #333333;
      margin-bottom: 5px;
    }

    .personal-visits-list__item-diagnosis-description {
      font-size: 14px;
      line-height: 18px;
      color: #696666;
    }
  }

  .personal-visits-list__item-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e3f6ff;
    border: 2px solid #d0e5f0;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 10px 20px;
    width: 100%;
    flex: 0 0 auto;
    margin-top: 20px;
    transition: all 0.15s ease-in-out;
    &:hover {
      background-color: #fff;
    }
    @media (min-width: 768px) {
      max-width: 192px;
      margin-top: 0;
      padding: 20px 25px;
    }

    @media (max-width: 767px) {
      img {
        height: 30px !important;
        width: auto !important;
      }
    }

    .personal-visits-list__item-button-icon {
      margin-right: 14px;
      svg {
        width: 24px;
        height: auto;
        @media (min-width: 768px) {
          width: 32px;
        }
      }
    }

    .personal-visits-list__item-button-description {
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      color: #094fa3;
    }
  }
}

.personal-recipes {
  width: 100%;
  border-top: 1px solid #d0e5f0;
  margin-top: 20px;
  padding-top: 20px;

  .personal-recipes-title {
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    color: #000000;
    margin-bottom: 20px;
    span {
      font-weight: normal;
      font-size: 18px;
      line-height: 23px;
      color: #979797;
    }
  }

  .personal-recipes-list {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  .personal-recipes-list-item-about {
    margin-bottom: 10px;
    .personal-recipes-list-item-about-title {
      font-weight: bold;
      font-size: 14px;
      line-height: 22px;
      color: #333333;
      margin-bottom: 10px;
    }

    .personal-recipes-list-item-about-description {
      font-size: 14px;
      line-height: 22px;
      color: #696666;
    }
  }

  .personal-recipes-list-item-use-wrapper {
    @media (min-width: 768px) {
      display: flex;
      justify-content: space-between;
    }
  }

  .personal-recipes-list-item-use {
    margin-bottom: 15px;
    @media (min-width: 768px) {
      margin-bottom: 0;
    }
    .personal-recipes-list-item-use-title {
      font-weight: bold;
      font-size: 14px;
      line-height: 22px;
      color: #333333;
      margin-bottom: 5px;
    }
    .personal-recipes-list-item-use-description {
      font-size: 14px;
      line-height: 22px;
      color: #696666;
      display: flex;
      align-items: flex-end;
      a {
        color: #094fa3;
      }
      .fas {
        font-size: 16px;
        color: #1a7ef7;
        margin-left: 5px;
      }
    }
  }

  .personal-recipes-list-item-button {
    padding: 10px 10px 10px 20px;
    border: 1px dashed #1a7ef7;
    box-sizing: border-box;
    border-radius: 5px;
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 180px;
    flex: 0 0 auto;
    margin-left: auto;
    @media (max-width: 767px) {
      margin-left: 0;
      padding: 10px;
      max-width: 165px;
    }
    &:hover {
      .personal-recipes-list-item-button-icon {
        .fas {
          &:before {
            display: inline-block;
          }
        }
      }
    }
    .personal-recipes-list-item-button-icon {
      width: 30px;
      height: 30px;
      background-color: #1a7ef7;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      .fas {
        font-size: 12px;
        color: #ffffff;
      }
    }

    .personal-recipes-list-item-button-description {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: #094fa3;
    }

    &.disabled {
      border: none;
    }
  }

  .personal-recipes-list-item {
    padding: 20px 15px;
    background-color: #f8f8f8;
    margin-bottom: 15px;
    border-radius: 4px;
    @media (max-width: 767px) {
      background-color: #efeff4;
    }
    @media (min-width: 768px) {
      display: flex;
      align-items: flex-start;
      padding: 20px;
      margin-bottom: 10px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  .personal-recipes-list-item-drug {
    @media (min-width: 768px) {
      padding-right: 65px;
      width: 100%;
    }
  }
}

.personal-recipes-wrapper {
  @media (max-width: 767px) {
    margin-left: -15px;
    margin-right: -15px;
    background-color: #ffffff;
    padding-top: 5px;
    margin-top: 15px;
    .personal-recipes-title {
      margin-left: 20px;
      margin-bottom: 0;
      padding-bottom: 10px;
    }

    .personal-recipes {
      margin-top: 0 !important;
    }

    .personal-recipes-data-list__item {
      padding: 15px 20px !important;
    }
  }

  .personal-recipes {
    border: none;
    margin-top: 25px;
    padding-top: 0;
  }
}

.personal-recipes-data-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
  .personal-recipes-data-list__item-date {
    .personal-recipes-data-list__item-date-title {
      font-weight: bold;
      font-size: 14px;
      line-height: 24px;
      color: #333333;
      margin-bottom: 8px;
    }

    .personal-recipes-data-list__item-date-description {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #696666;
      display: flex;
      align-items: center;
      .fas {
        font-size: 12px;
        color: #696666;
        margin-right: 7px;
      }
    }
  }

  .personal-recipes-data-list__item-link {
    flex: 0 0 auto;
    max-width: 128px;
    width: 100%;
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    border-radius: 5px;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: #1a7ef7;
    border: 1px solid #d0e5f0;
    transition: all 0.15s ease-in-out;
    &:hover {
      text-decoration: none;
      background-color: #fafafa;
    }
    @media (min-width: 768px) {
      max-width: 200px;
      padding: 15px;
    }
  }

  .personal-recipes-data-list__item {
    background-color: #ffffff;
    box-shadow: 0px 2px 10px rgba(9, 79, 163, 0.1);
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 15px;
    @media (min-width: 768px) {
      padding: 20px 25px 25px 25px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .buy {
      width: 100%;
      height: 50px;
      font-weight: 700;
      font-size: 14px;
      line-height: 33px;
      margin-top: 25px;

      .fas {
        margin-right: 15px;
      }
    }
  }

  .personal-recipes-data-list__item-information {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }
}

.user-cabinet-navigation--home {
  padding-bottom: 50px;
  padding-top: 30px;
  background-color: #edf4f8;
  @media (max-width: 767px) {
    padding-top: 15px;
    padding-bottom: 36px;
    background-color: #edf4f8;
  }
  .user-cabinet-navigation__item-status {
    margin-left: auto;
    .fas {
      font-size: 12px;
      color: #ff4040;
    }
  }

  .user-cabinet-navigation__item {
    margin-bottom: 5px;
    transition: all 0.15s ease-in-out;
    @media (min-width: 768px) {
      margin-bottom: 15px;
    }

    @media (min-width: 992px) {
      margin-bottom: 30px;
      height: 80px;
    }

    &.show {
      i {
        transform: rotate(180deg);
      }
    }

    .dropdown-toggle {
      background-color: #ffffff;
      border: 1px solid #d0e5f0;
      box-shadow: 0px 2px 4px rgba(9, 79, 163, 0.15);
      border-radius: 5px;
      padding-left: 5px;
      padding-right: 15px;
      position: relative;
      &:after {
        display: none;
      }
      &:focus {
        background-color: #ffffff;
      }

      &:hover {
        background-color: #ffffff;
        .user-cabinet-navigation__item-tooltip {
          display: flex;
        }
      }

      .user-cabinet-navigation__item-description-content {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #1c5caa;
      }

      .user-cabinet-navigation__item-description-content-number {
        margin-top: 5px;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        color: #979797;
        text-align: left;
      }

      .user-cabinet-navigation__item-description-icon-dropdown {
        margin-left: auto;
        position: relative;
        &:before {
          content: '';
          position: absolute;
          left: -14px;
          top: -14px;
          width: 1px;
          height: 50px;
          background-color: #d0e5f0;
        }
        i {
          color: #094fa3;
          font-size: 16px;
        }
      }

      .user-cabinet-navigation__item-description-icon {
        background-color: #1a7ef7;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        margin-right: 10px;
        img {
          width: 28px;
          height: 28px;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .container {
      padding-left: 5px;
      padding-right: 5px;
    }

    .user-cabinet-wrapper {
      padding-top: 5px;
      margin-top: -15px;
      padding-bottom: 0;
      background-color: #edf4f8;
    }
  }
}

.institution-about__address {
  padding: 20px;

  .institution-about__address-wrapper {
    display: flex;
    position: relative;
    flex-direction: column;
    @media (min-width: 992px) {
      flex-direction: row;
    }

    .rating-wrapper {
      display: block;
      position: absolute;
      left: 100px;
      top: -19px;
      width: auto;
      @media (min-width: 768px) {
        left: 150px;
        top: 21px;
      }
      @media (min-width: 992px) {
        display: none;
      }
    }
  }

  .institution-about__icon-wrapper {
    width: 100px;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    flex: 0 0 auto;
    margin-top: -50px;
    background-color: #fff;
    @media (min-width: 768px) {
      margin-top: -70px;
      width: 160px;
      height: 160px;
    }
    @media (min-width: 992px) {
      width: auto;
      height: auto;
      margin-top: 0;
      align-items: baseline;
      justify-content: start;

      .rating-wrapper {
        display: block;
        position: inherit;
      }
    }
  }

  .institution-about__icon {
    border-radius: 5px;
    width: 70px;
    height: 70px;
    background-position: top;
    background-size: cover;
    @media (min-width: 768px) {
      width: 130px;
      height: 130px;
    }

    @media (min-width: 992px) {
      margin-right: 20px;
    }
  }

  .institution-about__description-title {
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    color: #1d1b1b;
    margin-bottom: 10px;
    a {
      &:hover {
        text-decoration: underline;
      }
    }
    @media (min-width: 768px) {
      font-size: 20px;
      line-height: 26px;
    }

    a {
      text-decoration: none;
      color: inherit;
    }
  }

  .institution-about__description-appellation {
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: #979797;
    margin-bottom: 15px;
  }

  .institution-about__description-direction {
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
    color: #094fa3;

    a {
      color: inherit;
      text-decoration: underline;
    }

    .fas {
      font-size: 16px;
      color: #094fa3;
      margin-right: 10px;
    }
  }

  .institution-about__description-description {
    text-align: justify;
    font-size: 14px;
    line-height: 24px;
    color: #5b5b5b;
    white-space: pre-line;
    
    .fas {
      color: #1c5caa;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .rating-wrapper {
    width: 100%;
    margin-top: 15px;
    display: none;

    .rating-index {
      margin-top: 0;
      margin-left: 20px;
    }

    @media (min-width: 992px) {
      margin-left: 0;
      display: flex;
      align-items: center;
    }
  }

  // .institution-about__address-map {
  //   position: absolute;
  //   left: 225px;
  //   top: -3px;
  //   width: 40px;
  //   height: 40px;
  //   background-color: #e3f6ff;
  //   border: 1px solid #d0e5f0;
  //   box-sizing: border-box;
  //   border-radius: 5px;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   flex: 0 0 auto;
  //   @media (min-width: 768px) {
  //     left: 305px;
  //     top: 36px;
  //   }
  //   @media (min-width: 992px) {
  //     display: none;
  //   }
  //   .fas {
  //     font-size: 16px;
  //     color: #094fa3;
  //     &:before {
  //       display: inline-block;
  //     }
  //   }
  // }
}

.institution-about {
  background-color: #ffffff;
  box-shadow: 0px 2px 10px rgba(9, 79, 163, 0.1);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  margin-top: 45px;
  @media (min-width: 768px) {
    margin-top: 80px;
    justify-content: space-between;
    flex-direction: row;
  }

  @media (min-width: 992px) {
    margin-top: 0;
  }
}

.institution-about__date {
  margin-bottom: 10px;
  margin-top: 15px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #333333;
  @media (min-width: 768px) {
    margin-top: 20px;
    margin-bottom: 0;
  }

  @media (min-width: 992px) {
    margin-bottom: 10px;
  }

  &:first-child {
    margin-top: 15px;
    @media (min-width: 768px) {
      margin-top: 0;
    }
  }
}

.institution-about__link {
  display: flex;
  align-items: center;
  margin-top: 15px;
  padding: 13px 10px;
  background-color: #efeff4;
  border-radius: 5px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: rgba(51, 51, 51, 0.7);
  white-space: nowrap;
  @media (min-width: 768px) {
    padding: 13px 16px;
  }
  &:hover {
    color: rgba(51, 51, 51, 0.7);
  }
  span {
    color: #094fa3;
    margin-right: 5px;
    margin-left: 5px;
  }

  i {
    margin-left: auto;
    color: #094fa3;
  }
}

.rating-wrapper {
  display: flex;
  align-items: center;
  margin-top: auto;
  margin-bottom: 13px;
  @media (min-width: 768px) {
    margin-bottom: 10px;
    margin-top: 0;
    margin-left: 12px;
  }
  @media (min-width: 1200px) {
    margin-left: 0;
  }
  .rating {
    background-color: #efeff4;
    border-radius: 4px;
    padding: 7px 10px 9px 10px;
    display: inline-flex;
    align-items: center;
    min-width: 113px;
    height: 40px;
    @media (min-width: 768px) {
      padding: 7px 10px 9px 10px;
      min-width: 130px;
    }

    &.empty {
      background-color: inherit;
      display: none;
    }

    &.cursor:hover {
      cursor: pointer;
    }

    &.white {
      background-color: white;
    }

    .rating-list {
      display: flex;
      padding: 0;
      margin: 0;
      align-items: center;
      list-style-type: none;
      .rating-list-item {
        margin-right: 1px;
        display: flex;
        align-items: center;
        &:last-child {
          margin-right: 0;
        }
        .fas {
          font-size: 10px;
          color: #ff9501;
          @media (min-width: 768px) {
            font-size: 12px;
          }
        }
        .far {
          font-size: 10px;
          color: #d8d8d8;
          @media (min-width: 768px) {
            font-size: 12px;
          }
        }
      }
    }

    .rating-numeral {
      font-weight: bold;
      font-size: 12px;
      line-height: 16px;
      color: #979797;
      margin-left: 6px;

      strong {
        font-size: 14px;
        line-height: 18px;
        color: #1d1b1b;
        @media (min-width: 768px) {
          font-size: 18px;
          line-height: 23px;
          margin-top: 0;
        }
      }
    }
  }
  .rating-details {
    margin-left: 5px;
    position: relative;
    display: inline-flex;
    background-color: #efeff4;
    border-radius: 4px;
    padding: 6px 12px 8px 12px;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    color: #094fa3;
    .fas {
      font-size: 12px;
      color: #efeff4;
      position: absolute;
      left: -4px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .rating-index {
    margin-left: 20px;
  }
}

.institution-about__information-connection {
  display: flex;
  margin-bottom: 15px;
  @media (min-width: 768px) {
    margin-bottom: 0;
  }
  .fas {
    font-size: 16px;
    color: #094fa3;
    margin-right: 15px;
    margin-top: 2px;
  }
  .institution-about__information-connection-list {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }
  .institution-about__information-connection-list-item {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #333333;
    &.empty {
      font-weight: normal;
      color: #9b9b9b;
    }
  }
}

.institution-about__information {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;

  @media (min-width: 768px) {
    width: 290px;
  }
  @media (min-width: 768px) {
    border-left: 1px solid #d0e5f0;
  }
  .institution-about__information-time {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    @media (min-width: 768px) {
      padding-bottom: 15px;
      border-bottom: 1px solid #d0e5f0;
    }
    .far {
      font-size: 16px;
      color: #094fa3;
      margin-right: 15px;
    }
    .institution-about__information-time-item {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: #333333;
      &.empty {
        color: #9b9b9b;
        font-weight: normal;
      }
    }
  }

  .institution-about__remained-medicines {
    @media (min-width: 768px) {
      margin-top: 20px;
    }
  }

  .institution-about__information-link {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e3f6ff;
    border: 1px solid #d0e5f0;
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: #094fa3;
    padding: 11px 10px;
    @media (min-width: 992px) {
      min-width: 250px;
    }
    i {
      font-size: 16px;
      color: #094fa3;
      margin-right: 10px;
      &:before {
        display: inline-block;
      }
    }
  }
}

.institution-wrapper {
  background-color: #edf4f8;
  padding-top: 15px;
  padding-bottom: 90px;
  .container {
    padding-left: 0;
    padding-right: 0;
    @media (min-width: 768px) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  .row {
    margin-left: 0;
    margin-right: 0;
    @media (min-width: 768px) {
      margin-left: -15px;
      margin-right: -15px;
    }
  }

  .breadcrumbs-wrapper {
    padding-left: 20px;
    @media (min-width: 768px) {
      padding-left: 0;
    }
  }

  .institution-not-found {
    padding-top: 50px;
    text-align: center;
  }
}

.institution-info__header {
  position: relative;
  padding: 15px 80px 15px 20px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #d0e5f0;
  @media (min-width: 768px) {
    padding: 15px 20px 15px 25px;
    flex-direction: row;
    margin-bottom: 15px;
  }
  .institution-info__header-title {
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    color: #333333;
    margin-bottom: 10px;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }

    @media (min-width: 768px) {
      font-size: 20px;
      line-height: 34px;
      margin-right: 25px;
      margin-bottom: 0;
    }
  }

  .institution-info__header-icon {
    position: absolute;
    right: 20px;
    top: 15px;
    background-color: #e3f6ff;
    border: 1px solid #d0e5f0;
    box-sizing: border-box;
    border-radius: 4px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
    margin-left: auto;
    border-bottom: 1px solid #d0e5f0;
    cursor: pointer;
    &:hover {
      opacity: 0.75;
    }
    @media (min-width: 768px) {
      position: relative;
      right: auto;
      top: auto;
    }
    .fas {
      font-size: 16px;
      color: #094fa3;
    }
  }
}

.institution-info {
  background-color: #ffffff;
  box-shadow: 0px 2px 10px rgba(9, 79, 163, 0.1);
  border-radius: 5px;
  .row {
    margin-left: 0;
    margin-right: 0;
    @media (min-width: 768px) {
      margin-right: -15px;
      margin-left: -15px;
    }
  }

  .col-12 {
    padding-left: 0;
    padding-right: 0;
    @media (min-width: 768px) {
      padding-right: 15px;
      padding-left: 15px;
    }
  }

  .institution-info__indicator {
    .institution-info__indicator-list-item {
      border: none;
    }
  }
}

.institution-info__indicator-list {
  padding: 0;
  margin: 0;
  list-style-type: none;

  .institution-info__indicator-list-item {
    display: flex;
    justify-content: space-between;
  }

  .institution-info__phones-wrapper {
    display: flex;
    margin-left: 30px;

    .fas {
      font-size: 16px;
      color: #094fa3;
      margin-right: 15px;
      margin-top: 2px;
    }

    .institution-info__indicator-list-item {
      margin-left: 0;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #333;
      padding: 0;
      border: none;
    }
  }

  .institution-info__indicator-list-description {
    font-size: 14px;
    line-height: 20px;
    color: #696666;

    &.empty {
      color: #9b9b9b;
    }

    &.link {
      font-size: 14px;
      line-height: 22px;
      font-weight: 500;
      color: #094fa3;
      text-decoration: underline;

      &.span {
        text-decoration: none;
      }

      .fas {
        margin-right: 10px;
      }
    }
  }
  .institution-info__indicator-list-count {
    font-size: 14px;
    line-height: 18px;
    color: #859ead;
    strong {
      font-size: 16px;
      line-height: 21px;
      color: #1a7ef7;
    }
    .far {
      font-size: 12px;
      color: #bce0f5;
      margin-left: 5px;
    }
  }
}

.institution-info__indicator {
  .institution-info__indicator-item {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .institution-info__indicator-title {
    padding: 10px 20px;
    background-color: #efeff4;
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    color: #333333;
    @media (min-width: 768px) {
      padding: 15px 25px;
      background-color: #f8f8f8;
    }
  }

  .institution-info__indicator-list-item {
    padding: 10px 20px 15px 0;
    margin-left: 20px;
    margin-right: 20px;
    border-bottom: 1px solid #d0e5f0;
    flex-direction: column;
    @media (min-width: 768px) {
      margin-left: 30px;
      flex-direction: row;
      margin-right: 0;
      padding: 15px 20px 15px 0;
    }
    &:last-child {
      border-bottom: none;
    }
  }

  .institution-info__indicator-describe {
    margin: 15px 20px 30px 20px;
    font-size: 14px;
    line-height: 24px;
    color: #5b5b5b;
    white-space: pre-line;
    
    @media (min-width: 768px) {
      margin: 20px 20px 20px 30px;
    }

    &.empty {
      color: #9b9b9b;
    }
  }
}

.institution-info__medic-list {
  list-style-type: none;
  padding: 0 20px;
  margin: 0;
  @media (min-width: 768px) {
    padding: 15px 20px 0 30px;
  }

  @media (min-width: 992px) {
    padding: 0;
  }

  .institution-info__medic-list-item {
    display: flex;
    margin-bottom: 25px;
    flex-direction: column;
    @media (min-width: 768px) {
      flex-direction: row;
    }
    &:last-child {
      margin-bottom: 0;
    }
    .rating-wrapper {
      margin-top: auto;
      margin-left: 0;
    }
  }
}

.institution-info__medic {
  .institution-info__medic-title {
    padding: 10px 20px;
    background-color: #efeff4;
    margin-bottom: 15px;
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    color: #333333;
    @media (min-width: 768px) {
      padding: 15px 25px;
      background-color: #f8f8f8;
    }
  }

  .institution-info__medic-icon {
    width: 90px;
    height: 90px;
    background-size: cover;
    background-position: top;
    margin-right: 15px;
    margin-bottom: 10px;
    flex: 0 0 auto;
    @media (min-width: 768px) {
      width: 120px;
      height: 120px;
      margin-bottom: 0;
    }
  }

  .institution-info__medic-icon-wrapper {
    display: flex;
    .rating-wrapper {
      display: block;
      margin-bottom: 10px;
      @media (min-width: 768px) {
        display: none;
      }
    }
  }

  .institution-info__medic-info-title {
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    color: #1d1b1b;
    margin-bottom: 5px;
    @media (min-width: 768px) {
      font-size: 20px;
      line-height: 26px;
      margin-bottom: 10px;
    }
  }

  .institution-info__medic-info-subtitle {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    color: #979797;
    @media (min-width: 768px) {
      font-size: 14px;
      line-height: 18px;
    }

    &.empty {
      margin-left: 30px;
      color: #9b9b9b;
      text-transform: none;
      font-weight: normal;
    }
  }

  .institution-info__medic-about {
    display: flex;
    flex-direction: column;
  }

  .pagination {
    justify-content: center;
    margin: 30px 0 45px 0;
    @media (min-width: 992px) {
      justify-content: flex-start;
    }
  }

  .rating-wrapper {
    display: none;
    @media (min-width: 768px) {
      display: block;
    }
  }
}

.pagination {
  margin: 0;
  padding: 30px 0 20px 0;
  flex-wrap: wrap;
  @media (min-width: 590px) {
    padding-bottom: 30px;
  }
  @media (min-width: 768px) {
    padding: 30px;
  }

  .page-item {
    padding-bottom: 20px;
  }

  .page-link {
    width: 40px;
    height: 40px;
    background-color: #e3f6ff;
    border: 1px solid #d0e5f0;
    font-size: 16px;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 0;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.tabs-wrapper {
  margin-top: -66px;
  .nav-link {
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    color: #5b5b5b;
    padding: 14px 15px;
    border-left: 1px solid #d0e5f0;
    border-top: 1px solid #d0e5f0;
    transition: all 0.15s ease-in-out;

    span {
      font-weight: bold;
      font-size: 12px;
      line-height: 16px;
      color: #979797;
      padding: 5px;
      background-color: #ffffff;
      border: 1px solid #d0e5f0;
      border-radius: 5px;
    }
    &.active {
      color: #1a7ef7;
      background-color: #edf4f8;
      span {
        color: #333333;
      }
    }
  }

  .nav-item {
    &:first-child {
      .nav-link {
        border-top-left-radius: 4px;
      }
    }

    &:last-child {
      .nav-link {
        border-right: 1px solid #d0e5f0;
        border-top-right-radius: 4px;
      }
    }
  }

  .tab-content {
    background-color: transparent;
    padding: 15px 0 0 0;

    .institution-about__information {
      justify-content: space-between;

      .institution-about__remained-medicines {
        margin-top: 0;
      }
    }
    @media (max-width: 767px) {
      .institution-about__address {
        padding-bottom: 0;
      }

      .institution-about__information {
        padding-top: 0;
        padding-bottom: 5px;
      }
    }
  }

  .maps-tab {
    margin-top: -15px;
    height: 527px;

    .maps-tab-content {
      position: absolute;
      left: 0;
      width: 100%;
    }
  }

  .map-search-link {
    color: #094fa3;
    transition: all 0.15s ease-in-out;
    &:hover {
      background-color: #1a7ef7;
      color: #fff;
      .far,
      .fas {
        color: #fff;
      }
    }
    .far {
      transition: all 0.15s ease-in-out;
    }
  }

  .schedule .schedule-time-list__item.more ul {
    width: 252px;
    max-width: 252px;
  }

  .schedule .schedule-time-list__item.more {
    &:last-child {
      margin-bottom: 10px;
    }
  }
}

.maps-tab-content {
  @media (max-width: 767px) {
    margin-left: -20px;
    margin-right: -20px;
  }
}

.map-search-link {
  height: 50px;
  display: inline-flex;
  align-items: center;
  background-color: #e3f6ff;
  border-top: 1px solid #d0e5f0;
  border-right: 1px solid #d0e5f0;
  border-left: 1px solid #d0e5f0;
  border-bottom: inherit;
  outline: none;
  box-sizing: border-box;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #094fa3;
  padding: 16px 12px;
  order: 2;
  @media (min-width: 768px) {
    padding: 16px 20px;
    order: 1;
  }
  &:hover {
    .far,
    .fas {
      &:before {
        display: inline-block;
      }
    }
  }
  .far {
    margin-right: 5px;
    font-size: 16px;
    color: #094fa3;
    transition: all 0.15s ease-in-out;
  }
  .fas {
    margin-right: 5px;
    font-size: 18px;
    color: #094fa3;
    transition: all 0.15s ease-in-out;
  }
}

.filter {
  position: relative;
  height: 50px;
  display: inline-flex;
  align-items: center;
  background-color: #e3f6ff;
  border-top: 1px solid #d0e5f0;
  border-right: 1px solid #d0e5f0;
  border-left: 1px solid #d0e5f0;
  border-bottom: inherit;
  outline: none;
  box-sizing: border-box;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #094fa3;
  padding: 14px 12px;
  order: 1;
  margin-right: 10px;
  transition: all 0.15s ease-in-out;
  @media (min-width: 768px) {
    padding: 14px 16px;
    order: 2;
    margin-right: 0;
  }
  &:hover {
    background-color: #1a7ef7 !important;
    &:before {
      color: #fff;
    }
    .fas {
      color: #fff !important;
      &:before {
        display: inline-block;
      }
    }
  }
  .fas {
    font-size: 16px;
    color: #094fa3;
    order: -1;
    margin-right: 7px;
    transition: all 0.15s ease-in-out;
    @media (min-width: 768px) {
      order: 1;
      margin-left: 25px;
      margin-right: 0;
      font-size: 20px;
    }
  }
}

.calendar {
  position: relative;
  padding: 14px;
  display: inline-flex;
  align-items: center;
  background-color: #e3f6ff;
  border-top: 1px solid #d0e5f0;
  border-right: 1px solid #d0e5f0;
  border-left: 1px solid #d0e5f0;
  border-bottom: inherit;
  outline: none;
  box-sizing: border-box;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #094fa3;
  margin-left: 10px;
  min-width: 50px;

  .far,
  .fas {
    font-size: 20px;
    color: #094fa3;
  }

  @media screen and (min-width: 1200px) {
    display: none;
  }
}

.navigation-helpers {
  display: flex;
  height: 50px;

  .map-search-link {
    display: flex;
    margin-right: 15px;
  }
}

.navigation {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  height: 50px;
  position: relative;
  border-bottom: 1px solid #d0e5f0;
  z-index: 2;

  .nav {
    height: 50px;
    display: none;
    @media (min-width: 768px) {
      display: flex;
      flex-wrap: nowrap;
    }
  }

  .schedule-wrapper {
    margin-bottom: -1px;
    margin-left: 10px;
  }
}

.institution-list-wrapper {
  padding-top: 15px;
  border-top: 1px solid #d0e5f0;
  background-color: #edf4f8;
  position: relative;

  .institution-about__address .institution-about__icon-wrapper {
    margin-left: -20px;
    @media (min-width: 768px) {
      margin-left: -15px;
    }

    @media (min-width: 992px) {
      margin-left: 0;
    }
  }

  .institution-about__address .institution-about__address-wrapper .rating-wrapper {
    left: 80px;
    @media (min-width: 768px) {
      left: 134px;
    }
  }
}

.medic-feature {
  background-color: #ffffff;
  box-shadow: 0px 2px 10px rgba(9, 79, 163, 0.1);
  border-radius: 5px;
  .col-12 {
    padding-left: 0;
    padding-right: 0;
    @media (min-width: 1200px) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  .medic-feature-schedule-wrapper {
    padding: 30px;
    height: 100%;
  }
  .schedule-wrapper {
    padding: 0;
    &::before {
      background-color: inherit;
    }

    .schedule-title-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 25px;

      .schedule-title {
        margin: 0;
      }
    }
  }
}

.medic-feature-profile-status {
  flex: 0 0 auto;
  display: flex;
  margin-bottom: 5px;
  @media (min-width: 768px) {
    margin-right: 25px;
    margin-bottom: 0;
    flex-direction: column;
  }

  @media (min-width: 1200px) {
    margin-top: 0;
  }
  .medic-feature-profile-status__icon {
    width: 70px;
    height: 70px;
    border-radius: 5px;
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;

    @media (min-width: 768px) {
      width: 130px;
      height: 130px;
    }
    @media (min-width: 1200px) {
      margin-bottom: 15px;
    }
  }
}

.medic-feature-profile-status__icon-wrapper {
  width: 100px;
  height: 100px;
  border-radius: 5px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 768px) {
    width: 155px;
    height: 155px;
  }
  @media (min-width: 1200px) {
    width: auto;
    height: auto;
  }
}

.medic-feature-profile-information {
  @media (min-width: 768px) {
    margin-top: 20px;
  }

  @media (min-width: 1200px) {
    margin-top: 0;
  }

  .tab-content {
    padding: 0;
  }

  .medic-feature-profile-information__title {
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    color: #1d1b1b;
    margin-bottom: 5px;
    display: inline-block;
    a {
      &.disabled {
        &:hover {
          cursor: default;
          text-decoration: none;
        }
      }
      &:hover {
        text-decoration: underline;
      }
    }
    @media (min-width: 768px) {
      font-size: 20px;
      line-height: 26px;
      margin-bottom: 10px;
    }
  }

  .medic-feature-profile-information__position {
    font-size: 12px;
    line-height: 16px;
    color: #979797;
    @media (min-width: 768px) {
      font-size: 14px;
      line-height: 18px;
    }
  }

  .medic-feature-profile-information__work-list {
    list-style-type: none;
    margin: 15px 0 0 0;
    padding: 0;
    @media (min-width: 768px) {
      .medic-feature-profile-information__work-list-head {
        display: none;
      }

      .medic-feature-profile-information__work-list-body {
        .collapse {
          display: block;
        }
      }
    }
  }

  .medic-feature-profile-information__work-list-item-title {
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    color: #1d1b1b;
    margin-bottom: 10px;
    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 18px;
    }
  }

  .medic-feature-profile-information__work-list-item-structure {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 10px;
    font-weight: 500;

    a {
      color: #094fa3;
      display: flex;
      align-items: baseline;
      @media (max-width: 767px) {
        font-size: 12px;
        line-height: 16px;
      }
      &:hover {
        color: #094fa3;
      }
      i {
        margin-right: 10px;
      }
    }
  }

  .medic-feature-profile-information__work-list-item-address {
    font-size: 14px;
    line-height: 18px;
    color: #094fa3;
    font-weight: 500;

    i {
      margin-right: 10px;
    }

    a {
      color: #094fa3;
      text-decoration: underline;
      @media (max-width: 767px) {
        font-size: 12px;
        line-height: 16px;
      }

      &:hover {
        color: #094fa3;
      }
    }
  }

  .medic-feature-profile-information__work-list-item {
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .medic-feature-profile-information__work-list-head {
    @media (max-width: 767px) {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      .medic-feature-profile-information__dropdown {
        padding: 12px 15px;
        background-color: #efeff4;
        border-radius: 4px;
        border: none;
        width: 100%;
        i {
          font-size: 12px;
          color: #094fa3;
          margin-left: 10px;
          &:before {
            display: inline-block;
          }
        }
      }
    }

    .medic-feature-profile-information__dropdown-item {
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      color: #094fa3;
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      @media (max-width: 767px) {
        font-size: 12px;
        line-height: 16px;
      }
      i {
        font-size: 12px;
        color: #094fa3;
        margin-left: 10px;
        &:before {
          display: inline-block;
        }
      }
      @media (max-width: 767px) {
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        color: #094fa3;
        display: flex;
        justify-content: space-between;
        align-items: baseline;
      }
    }
  }

  .medic-feature-profile-information__work-list-wrapper {
    padding: 15px;
    background-color: #efeff4;
    border-radius: 4px;
    margin-top: 10px;
    @media (max-width: 1199px) {
      margin-top: 0;
      padding-top: 0;
    }
  }

  .medic-feature-profile-information__dropdown {
    padding: 12px 15px;
    background-color: #efeff4;
    border-radius: 4px;
    border: none;
    width: 100%;
    @media (min-width: 1200px) {
      padding: 0;
      background-color: transparent;
      border-radius: 0;
      width: auto;
    }
  }

  .medic-feature-profile-information__place-service-position {
    margin-bottom: 10px;
  }
}

.medic-feature-profile {
  display: flex;
  flex-direction: column;
  padding: 0 20px 0 20px;
  @media (min-width: 768px) {
    padding: 0 15px 0 15px;
    flex-direction: row;
  }
  @media (min-width: 1200px) {
    padding: 30px 30px 0 30px;
  }
}

.medic-feature-wrapper {
  background-color: #edf4f8;
  padding-top: 45px;
  padding-bottom: 55px;
  position: relative;
  border-top: 1px solid #d0e5f0;
  @media (min-width: 1200px) {
    padding-top: 15px;
  }
  @media (max-width: 767px) {
    padding-bottom: 0;
  }

  .container {
    position: relative;
    padding-left: 0;
    padding-right: 0;
    @media (min-width: 1200px) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .row {
    margin-left: 0;
    margin-right: 0;
    @media (min-width: 1200px) {
      margin-left: -15px;
      margin-right: -15px;
    }
  }
  .breadcrumbs-wrapper {
    position: absolute;
    top: -83px;
    left: 15px;
    @media (min-width: 1200px) {
      top: -45px;
    }
  }

  .medic-feature-schedule-wrapper {
    @media (max-width: 767px) {
      display: none;
    }
  }
}

.medic-feature-profile-detail {
  padding: 30px 20px 30px 20px;
  @media (min-width: 1200px) {
    padding: 30px 30px 0 30px;
  }
}

.medic-feature-profile-detail-list {
  padding: 0;
  margin: 0;
  list-style-type: none;

  .medic-feature-profile-detail__title {
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    color: #1d1b1b;
    padding: 10px 20px;
    margin-left: -20px;
    margin-right: -20px;
    background-color: #f8f8f8;
    @media (min-width: 768px) {
      font-size: 20px;
      line-height: 26px;
    }
    @media (min-width: 1200px) {
      margin-left: -30px;
      margin-right: 0;
      padding: 15px 30px;
    }
  }

  .medic-feature-profile-detail__indicator {
    padding: 0;
    margin: 20px 0 35px 0;
    list-style-type: none;
  }

  .medic-feature-profile-detail__indicator-item {
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #f8f8f8;
    @media (min-width: 768px) {
      flex-direction: row;
      justify-content: space-between;
    }
    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: none;
    }
  }

  .medic-feature-profile-detail__indicator-item-description {
    font-size: 14px;
    line-height: 20px;
    color: #333333;
    margin-bottom: 5px;
    @media (min-width: 1200px) {
      padding-right: 30px;
      margin-bottom: 0;
    }
  }

  .medic-feature-profile-detail__indicator-item-count {
    font-size: 14px;
    line-height: 18px;
    color: #859ead;
    margin-right: 20px;
    strong {
      font-weight: bold;
      font-size: 16px;
      line-height: 21px;
      color: #1a7ef7;
    }

    .far {
      margin-left: 5px;
      font-size: 12px;
      color: #bce0f5;
    }
  }

  .medic-feature-profile-detail__description {
    margin: 20px 0 25px 0;
    p {
      font-size: 14px;
      line-height: 28px;
      color: #5b5b5b;
      margin-top: 0;
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.medic-feature-profile-detail__experience {
  .medic-feature-profile-detail__experience-list,
  .medic-feature-profile-detail__experience-info-list {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  .medic-feature-profile-detail__experience-info-list-item {
    position: relative;
    font-size: 14px;
    line-height: 28px;
    color: #5b5b5b;
    padding-left: 20px;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 12px;
      width: 6px;
      height: 6px;
      border-radius: 100%;
      background-color: #094fa3;
    }
  }

  .medic-feature-profile-detail__experience-title {
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    color: #333333;
    margin-bottom: 10px;
    margin-top: 15px;
  }

  .medic-feature-profile-detail__experience-list-item {
    margin-bottom: 25px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.medic-feature-profile-information-accrodion__head {
  @media (min-width: 768px) {
    display: none;
  }
  button {
    padding: 0;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: #094fa3;
    display: flex;
    align-items: baseline;
    border: none;
    margin-top: 20px;
    .fas {
      margin-left: 5px;
      font-size: 12px;
    }
    &:focus,
    &:hover {
      .fas {
        &:before {
          display: inline-block;
        }
      }
    }
  }
}

.medic-feature-profile-information-accrodion__body {
  .collapse {
    @media (min-width: 768px) {
      display: block !important;
    }
  }
}

.medic-feature-profile-wrapper {
  position: relative;

  @media (min-width: 1200px) {
    padding-bottom: 55px;
    border-right: 1px solid #d0e5f0;
  }

  .medic-feature-profile-status {
    @media (max-width: 767px) {
      margin-top: -35px;
    }
  }

  @media (max-width: 767px) {
    .schedule .schedule-body {
      margin-left: 0;
    }
  }
}

.schedule-wrapper {
  padding: 30px;
  position: relative;
  display: none;

  &.mobile {
    display: block;

    .schedule {
      margin-left: 1px;

      .schedule-navigation {
        width: 30px;
      }

      .schedule-date-list__item {
        min-width: 58px;
        padding: 8px 3px;
        &:hover {
          cursor: pointer;
        }

        &.active {
          background-color: #1a7ef7;

          .schedule-date-title,
          .schedule-date-description {
            color: #fff;
            strong {
              color: #fff;
            }
          }
        }
      }
    }
  }

  .schedule {
    height: 100%;

    .schedule_body {
      height: 100%;
    }
    .schedule-body__placeholder {
      width: 440px;
      display: flex;
      justify-content: center;
      font-weight: bold;
      height: 100%;
      font-size: 18px;
      align-items: center;
    }
  }
  @media (min-width: 1200px) {
    display: block;
  }
  &:before {
    content: '';
    position: absolute;
    left: -10px;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: rgba(255, 255, 255, 0);
  }

  .schedule-title {
    font-weight: bold;
    font-size: 24px;
    line-height: 31px;
    color: #1d1b1b;
    margin-bottom: 25px;
  }
}

.schedule-time-list__item__more-btn-wrapper {
  position: relative;
}

.schedule-body--mobile {
  .schedule-body__placeholder {
    width: 100%;
    height: 100%;
    font-weight: bold;
    font-size: 18px;

    @media (max-width: 767px) {
      height: auto;
    }

    @media (max-width: 400px) {
      font-size: 16px;
    }

    .schedule-body-no-result {
      padding-right: 20px;
    }
  }

  .schedule-list {
    overflow-x: auto;
  }
}

.schedule {
  .schedule-date-list {
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: flex;
    background-color: #e3f6ff;
    justify-content: space-around;
    position: relative;
    height: 50px;
  }

  .schedule-date-list-last {
    position: absolute;
    right: -21px;
    top: 52px;
    font-size: 14px;
    line-height: 20px;
    color: #dc8d29;
    border: 1px solid #f6c38b;
    border-radius: 5px;
    padding: 12px 23px 12px 12px;
    background-color: #fff0db;
    z-index: 1;

    i {
      font-size: 14px;
      color: #dc8d29;
      margin-right: 7px;
    }

    .close {
      position: absolute;
      right: 0;
      top: 5px;
      font-size: 16px;
    }

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      right: 23px;
      width: 0;
      height: 0;
      border-style: solid;
    }
    &:after {
      top: -11px;
      border-color: transparent transparent #fff0db transparent;
      border-width: 6px;
    }
    &:before {
      top: -12px;
      border-color: transparent transparent #f6c38b transparent;
      border-width: 6px;
    }
  }

  .schedule-date-list__item {
    user-select: none;
    padding: 8px 10px;
    border-right: 1px solid #d0e5f0;
    text-align: center;
    min-width: 88px;
    &:last-child {
      border-right: none;
    }
  }

  .schedule-date-title,
  .schedule-date-description {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #979797;
  }

  .schedule-date-description.today {
    color: #094fa3;
    font-weight: bold;
  }

  .schedule-date-description {
    &.mobile {
      font-size: 14px;
    }
    strong {
      color: #333333;
    }
  }

  .schedule-header {
    background-color: #e3f6ff;
    border: 1px solid #d0e5f0;
    border-radius: 5px;
    display: inline-flex;
    height: 100%;
  }

  .schedule-navigation {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    flex: 0 0 auto;
    cursor: pointer;
    transition: all 0.15s ease-in-out;
    &:hover {
      background-color: #1a7ef7;
      .fas {
        color: #fff;
      }
    }
    .fas {
      color: #094fa3;
      transition: all 0.15s ease-in-out;
    }
  }

  .schedule-navigation__prev {
    border-right: 1px solid #d0e5f0;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .schedule-navigation__next {
    border-left: 1px solid #d0e5f0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .schedule-list,
  .schedule-time-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .schedule-list {
    display: flex;
  }

  .schedule-list__item {
    position: relative;
    &:last-child {
      &:before {
        display: none;
      }
    }
    &:before {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      width: 1px;
      height: 100%;
      background-color: #d0e5f0;
    }
  }

  .schedule-time-list__item {
    width: 70px;
    height: 40px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    background-color: #e3f6ff;
    border: 1px solid #d0e5f0;
    box-sizing: border-box;
    border-radius: 4px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #094fa3;
    &:last-child {
      margin-bottom: 0;
    }
    &.more {
      cursor: pointer;
      ul {
        padding-bottom: 3px;
        padding-left: 7px;
        padding-right: 7px;
        top: 35px;
        max-width: 257px;
        width: 257px;
        max-height: 216px;
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 3px;
        }
        &::-webkit-scrollbar-thumb {
          background: #094fa3;
          border-radius: 10px;
        }
        &::-webkit-scrollbar-thumb:hover {
          background: #094fa3;
        }
        &::-webkit-scrollbar-track {
          background: #ffffff;
          border-radius: 0px;
        }
        button {
          margin-left: 5px;
          margin-right: 5px;
        }
      }
      .fas {
        color: #094fa3;
      }
    }
    &.empty {
      position: relative;
      background-color: #f8f8f8;
      &:before {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%);
        width: 30px;
        height: 2px;
        background-color: #d0e5ef;
      }
    }
  }

  .schedule-body {
    margin-top: 15px;
    margin-left: 18px;

    &.schedule-body--mobile {
      margin-left: 0;
    }
  }

  .schedule-time-list__item-icon {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: -1px;
    top: -1px;
    width: 12px;
    height: 12px;
    border-bottom-left-radius: 4px;
    border-top-right-radius: 4px;
    background-color: #094fa3;
    color: #ffffff;
    @media (min-width: 768px) {
      width: 14px;
      height: 14px;
    }
    svg {
      width: 5.14px;
      height: 5.14px;
      @media (min-width: 768px) {
        width: 6px;
        height: 6px;
      }
    }
  }

  .schedule-info {
    display: flex;
    align-items: baseline;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.25px;
    color: #094fa3;
    margin-top: 6px;
    @media (min-width: 768px) {
      margin-top: 20px;
      margin-left: -10px;
      align-items: center;
    }
  }

  .schedule-info__icon {
    width: 14px;
    height: 14px;
    border-radius: 5px;
    background-color: #094fa3;
    color: #ffffff;
    margin-right: 8px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.medic-feature-profile-information {
  width: 100%;

  .medic-feature-profile-information__title {
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
    @media (min-width: 768px) {
      font-size: 24px;
      line-height: 31px;
      margin-bottom: 15px;
    }

    a {
      color: #1d1b1b;
    }
  }

  .medic-feature-profile-information__position {
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    @media (min-width: 768px) {
      font-size: 14px;
      line-height: 18px;
    }
  }

  .medic-feature-profile-information-map {
    display: none;
    @media (min-width: 1200px) {
      width: 40px;
      height: 40px;
      background-color: #e3f6ff;
      border: 1px solid #d0e5f0;
      box-sizing: border-box;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 0 0 auto;
      margin-left: 30px;
      .fas {
        font-size: 16px;
        color: #094fa3;
        &:before {
          display: inline-block;
        }
      }
    }
  }

  .rating-wrapper {
    margin-left: 12px;
    display: none;
    @media (min-width: 1200px) {
      display: flex;
      margin-left: 0;
    }
  }

  .medic-feature-profile-information__calendar {
    &.mobile {
      @media (min-width: 1200px) {
        display: none;
      }
    }
  }

  .schedule {
    margin-top: 15px;
    margin-left: -2px;

    @media (min-width: 992px) {
      margin-left: -10px;
    }

    @media (min-width: 1200px) {
      display: none;
    }

    .schedule-time-list {
      display: flex;
    }

    .schedule-time-list__item {
      width: 60px;
      margin-left: 2px;
      margin-right: 2px;
      @media (min-width: 768px) {
        width: 70px;
        margin-left: 10px;
        margin-right: 10px;
      }
    }

    .schedule-title {
      margin-left: 2px;
      font-size: 12px;
      line-height: 16px;
      color: #979797;
      margin-bottom: 10px;
      @media (min-width: 768px) {
        font-size: 16px;
        line-height: 20px;
        margin-left: 10px;
      }
    }
  }
}

.data-list-wrapper,
.medic-feature-wrapper {
  .empty-list {
    padding: 10px;
    padding-bottom: 20px;
    text-align: center;
    color: #9b9b9b;
  }
}

.data-list-wrapper {
  padding-top: 15px;
  border-top: 1px solid #d0e5f0;
  background-color: #edf4f8;
  position: relative;

  .medic-feature-profile {
    padding: 15px;
    width: 100%;
    flex-direction: column;
    @media (min-width: 768px) {
      padding: 20px 15px 25px 15px;
    }
    @media (min-width: 1200px) {
      flex-direction: row;
      padding: 20px 20px 25px 20px;
    }
  }

  .medic-feature-profile-status__icon-wrapper {
    margin-left: -15px;
    @media (min-width: 768px) {
      margin-top: -15px;
    }
    @media (min-width: 1200px) {
      margin-top: 0;
      margin-left: 0;
    }
  }

  .medic-feature-profile-status {
    display: flex;
    flex-direction: row;
    margin-right: 0;
    @media (min-width: 1200px) {
      margin-right: 25px;
      flex-direction: column;
    }
  }

  .filter {
    padding: 14px;
    justify-content: center;
    font-weight: 600;
    @media (max-width: 767px) {
      padding: 14px 10px;
    }
    .fas {
      margin-left: 0;
      margin-right: 7px;
    }
  }

  .schedule-wrapper {
    padding: 0;
  }

  .navigation-helpers {
    margin-left: 0;
    margin-right: 0;
    @media (min-width: 768px) {
      margin-left: auto;
      margin-right: 0;
    }
  }

  .calendar {
    margin-left: 0;
    margin-right: 10px;
    height: 50px;
    @media (max-width: 767px) {
      padding: 14px 10px;
    }
  }

  .indicators {
    list-style: none;
    padding: 0;

    .rating-index {
      margin-top: 15px;
    }
  }

  .feature-card__item {
    margin-top: 30px;
    @media (max-width: 767px) {
      margin-top: 45px;
      &:first-child {
        margin-top: 30px;
      }
    }

    @media (min-width: 1200px) {
      margin-top: 0;
    }
  }

  .medic-feature-profile-information__map {
    display: none;
    @media (min-width: 1200px) {
      display: block;
    }
  }

  .map-search-link {
    justify-content: center;
    font-weight: 600;
    font-size: 14px;
    .far {
      margin-right: 7px;
    }
    @media (max-width: 767px) {
      padding: 14px 10px;
      margin-right: 0;
      white-space: nowrap;
    }
    @media (min-width: 768px) {
      width: auto;
      .far {
        margin-right: 5px;
      }
    }
  }

  .calendar-info {
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
    padding: 0 15px 0 10px;
    @media (min-width: 768px) {
      padding: 0 28px 0 10px;
    }
    @media (max-width: 767px) {
      padding-right: 5px;
    }
    .calendar-info-day {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #5b5b5b;
    }
    .calendar-info-date {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: #094fa3;
    }
  }

  .calendar-dots {
    position: relative;
    margin-left: 25px;
    @media (min-width: 768px) {
      display: none;
    }
    &:before {
      content: ':';
      font-size: 18px;
      color: #094fa3;
    }
  }
}

.feature-card__item {
  background-color: #ffffff;
  box-shadow: 0px 2px 10px rgba(9, 79, 163, 0.1);
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  &:last-child {
    margin-bottom: 0;
  }

  .medic-feature-profile {
    border-right: 1px solid #d0e5f0;
  }
  .schedule-wrapper {
    &:not(.mobile) {
      padding: 20px 29px;
    }
  }

  .schedule {
    &.mobile {
      @media (min-width: 1200px) {
        display: none;
      }
    }
  }

  .schedule-body {
    margin-top: 0;
    margin-left: -10px;
    margin-right: -10px;

    &.schedule-body--mobile {
      margin: 0;
    }
  }

  .schedule-body--mobile {
    margin: 0;
  }

  .schedule-time-list__item {
    width: 68px;
  }

  .medic-feature-profile-status {
    @media (max-width: 767px) {
      margin-top: -45px;
    }
  }

  .schedule-info {
    margin-left: 0;
    @media (min-width: 767px) {
      margin-left: 10px;
    }
  }
}

.medic-feature-profile-information__calendar {
  margin-top: 15px;

  @media (min-width: 1200px) {
    margin-top: 0;
    margin-left: 45px;
  }

  .medic-feature-profile-information__calendar-link {
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #094fa3;
    display: flex;
    align-items: center;

    span:hover {
      cursor: pointer;
      text-decoration: underline;
    }

    .fas {
      font-size: 12px;
      margin-left: 10px;

      @media (min-width: 1200px) {
        display: none;
      }
    }

    .far {
      font-size: 20px;
      margin-right: 10px;
    }
  }
}

.medic-feature-profile-information__place-wrapper {
  margin-top: 10px;
  &.accordion {
    .medic-feature-profile-information__calendar {
      display: none;
      @media (min-width: 1200px) {
        display: block;
      }
    }
  }

  @media (min-width: 768px) {
    margin-top: 25px;
  }

  .medic-feature-profile-information__place-head {
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    @media (min-width: 1200px) {
      flex-direction: row;
      align-items: center;
    }

    .medic-feature-profile-information__dropdown {
      padding: 12px 15px;
      background-color: #efeff4;
      border-radius: 4px;
      border: none;
      width: 100%;

      @media (min-width: 1200px) {
        padding: 0;
        background-color: transparent;
        border-radius: 0;
        width: auto;
      }

      .medic-feature-profile-information__dropdown-item {
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        color: #094fa3;
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        @media (min-width: 1200px) {
          justify-content: normal;
        }
        @media (max-width: 767px) {
          font-size: 12px;
          line-height: 16px;
        }
        .fas {
          font-size: 12px;
          color: #094fa3;
          margin-left: 10px;
          &:before {
            display: inline-block;
          }
        }
      }
    }
  }

  .card {
    border: none;
    display: flex;
    align-items: baseline;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .collapse {
    width: 100%;
  }
}

.medic-feature-profile-information__map {
  margin-top: 45px;
  .medic-feature-profile-information__map-link {
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: #094fa3;
    display: flex;
    align-items: center;
    .fas {
      font-size: 20px;
      color: #094fa3;
      margin-right: 10px;
    }
  }
}

.medic-feature-profile-information__place-service {
  padding: 15px;
  background-color: #efeff4;
  border-radius: 4px;
  margin-top: 10px;
  @media (max-width: 767px) {
    margin-top: 0;
    padding-top: 0;
  }
  .medic-feature-profile-information__place-service-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 10px;
    @media (max-width: 767px) {
      font-size: 12px;
      line-height: 16px;
    }

    a {
      color: #094fa3;
      display: flex;
      align-items: baseline;

      i {
        margin-right: 10px;
        &:before {
          display: inline-block;
        }
      }

      &:hover {
        color: #094fa3;
      }
    }
  }

  .medic-feature-profile-information__place-service-space {
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    color: #1d1b1b;
    display: flex;
    align-items: baseline;
    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 18px;
    }
    i {
      margin-right: 10px;
      flex: 0 0 auto;
    }
  }

  .medic-feature-profile-information__place-service-address {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 15px;
    color: #094fa3;
    @media (max-width: 767px) {
      font-size: 12px;
      line-height: 16px;
    }

    a {
      color: #094fa3;
      text-decoration: underline;
      display: flex;
      align-items: baseline;

      &:hover {
        color: #094fa3;
      }
    }

    i {
      font-size: 14px;
      color: #094fa3;
      margin-right: 10px;
      flex: 0 0 auto;
      &:before {
        display: inline-block;
      }
    }
  }

  .medic-feature-profile-information__place-service-position {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #979797;
    @media (max-width: 767px) {
      font-size: 12px;
      line-height: 16px;
    }
  }
}

.medic-feature-profile-information__jobs-wrapper {
  .medic-feature-profile-information__place-service {
    box-shadow: 6px 6px 0 0 #e8e8e8;
  }
}

.medic-feature-profile-information__jobs-pagination {
  display: flex;
  justify-content: flex-end;

  .medic-feature-profile-information__jobs-pagination-button {
    border: 1px solid #d0e5f0;
    box-sizing: border-box;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
    transition: all 0.15s ease-in-out;
    &:hover {
      background-color: #e3f6ff;
    }
    i {
      font-weight: 900;
      font-size: 16px;
      line-height: 18px;
      color: #094fa3;
      padding: 10px 15px;
    }
  }

  .medic-feature-profile-information__jobs-pagination-count {
    padding: 10px;
    line-height: 18px;

    .medic-feature-profile-information__jobs-pagination-current-page {
      color: #094fa3;
      font-size: 14px;
      font-weight: bold;
    }

    .medic-feature-profile-information__jobs-pagination-total-pages {
      color: gray;
      font-size: 13px;
    }
  }
}

.map-container {
  img {
    width: 100%;
  }
}

.medic-feature-profile-status-services {
  display: flex;
  align-items: flex-end;
  .medic-feature-profile-information-map {
    width: 40px;
    height: 40px;
    background-color: #e3f6ff;
    border: 1px solid #d0e5f0;
    box-sizing: border-box;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
    margin-left: 30px;
    @media (min-width: 1200px) {
      display: none;
    }
    .fas {
      font-size: 16px;
      color: #094fa3;
      &:before {
        display: inline-block;
      }
    }
  }

  .rating-wrapper {
    margin-bottom: 12px;
    margin-left: 0;
  }

  .medic-feature-profile-information-map {
    margin-bottom: 12px;
    margin-left: 15px;
  }
}

.modal-appointments-calendar {
  padding-right: 0 !important;

  .modal-dialog {
    max-width: 1188px;
    @media (min-width: 768px) {
      max-width: 748px;
    }

    @media (min-width: 992px) {
      max-width: 1004px;
    }

    @media (min-width: 1200px) {
      max-width: 1188px;
    }
  }

  .modal-content {
    width: 100%;
    padding: 20px 10px;
    margin: 0 10px;
    overflow: hidden;
    @media (min-width: 768px) {
      padding: 20px;
    }
  }

  .sub-modal-backdrop {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    background-color: rgba(99, 136, 157, 0.7);
    outline: 0;
    z-index: 1100;
  }

  .sub-modal {
    width: 280px;
    max-height: 400px;
    padding: 20px;
    background-color: white;
    top: 50%;
    transform: translateY(-50%);
    left: calc(50% - 140px);
    position: fixed;
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 0.3rem;
    outline: 0;
    z-index: 1200;

    .sub-modal-header {
      .sub-modal-header__title {
        font-size: 16px;
        font-weight: 700;
        color: #1d1b1b;
        margin-top: 5px;
      }

      .close {
        position: absolute;
        width: 42px;
        height: 42px;
        right: 10px;
        top: 10px;
      }
    }

    .sub-modal-body {
      margin: 20px -20px 0 -20px;
      padding: 15px 20px 0 20px;
      border-top: 1px solid #d0e5f0;
      overflow: hidden;

      .calendar-day__information {
        padding: 5px;
        background-color: #e3f6ff;

        &.off {
          background-color: #efeff4;
        }

        &:not(:first-of-type) {
          margin-top: 10px;
        }

        &:first-of-type {
          margin-top: 0;
        }
      }
    }
  }

  .modal-header {
    margin: 0 0 -5px;
    flex-direction: column;
    @media (min-width: 768px) {
      flex-direction: row;
      margin: 0 0 -15px;
    }

    .calendar-head__title {
      font-size: 18px;
      padding-bottom: 20px;
      position: relative;
      width: 100%;
      margin-bottom: 10px;
      @media (min-width: 768px) {
        font-size: 20px;
        width: auto;
        margin-bottom: 0;
        padding-bottom: 0;
      }

      @media (max-width: 767px) {
        padding-right: 50px;
      }
      &:before {
        content: '';
        position: absolute;
        left: -10px;
        bottom: 0;
        width: calc(100% + 20px);
        height: 1px;
        background-color: #d0e5f0;
        @media (min-width: 768px) {
          display: none;
        }
      }

      i {
        display: none;
        @media (min-width: 768px) {
          display: inline-block;
        }
      }
    }

    .calendar-head__description {
      font-size: 14px;
      font-weight: normal;

      .full_name {
        font-weight: 700;
      }

      .divider {
        display: none;
        @media (min-width: 768px) {
          display: inline-block;
        }
      }

      .date {
        color: #094fa3;
        display: block;
        @media (min-width: 768px) {
          display: inline-block;
        }
      }
    }
  }

  .modal-header .close {
    right: 10px;
    top: 10px;
    width: 42px;
    height: 42px;
    max-width: 1188px;
    @media (min-width: 768px) {
      width: 50px;
      height: 50px;
      top: -10px;
      right: -10px;
      position: relative;
    }
  }

  .calendar-day__information {
    position: relative;
    padding-left: 7px;
    margin-top: 7px;
    overflow: hidden;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 2px;
      height: 100%;
      border-radius: 4px;
      background-color: #1a7ef7;
    }

    &.off {
      background-color: #efeff4;

      &:before {
        background-color: #979797;
      }
    }

    .calendar-day__information-title {
      font-weight: bold;
      font-size: 12px;
      line-height: 16px;
      color: #094fa3;
      margin-bottom: 5px;
    }

    .calendar-day__information-description {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #5b5b5b;
    }

    .calendar-day__substitution {
      margin-top: 5px;
      color: #333333;
      font-size: 12px;

      .calendar-day__substitution_title {
        font-weight: 400;
      }

      a {
        margin: 5px 0;
        display: block;
        color: #333333;
        font-weight: 600;
      }
    }
  }

  .calendar-day__information-off {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #979797;
    margin-bottom: 5px;
    text-transform: capitalize;

    .fas {
      margin-right: 5px;
      font-size: 10px;
      color: #979797;
    }
  }

  .calendar-day__information-replace-title {
    font-size: 12px;
    line-height: 16px;
    color: #333333;
    margin-top: 5px;
  }

  .calendar-day__information-replace-description {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    display: block;
  }

  .modal-body {
    margin-top: 20px;
    margin-bottom: 0;

    .calendar-wrapper {
      background-color: #ffffff;

      .calendar {
        padding: 0;
        width: 100%;
        margin-left: 0;
        background-color: #ffffff;
        display: block;
        border: none;
      }

      .calendar-footer {
        padding-top: 10px;
        text-align: center;
        font-weight: bold;
      }

      .calendar-row {
        @media (min-width: 768px) {
          display: flex;
          justify-content: center;
        }

        &:not(:first-of-type) {
          .calendar-day {
            margin-top: -1px;
          }
        }
      }

      .calendar-day {
        width: 100%;
        min-height: 96px;
        border: 1px solid #d0e5f0;
        box-sizing: border-box;
        border-radius: 4px;
        overflow: hidden;
        padding: 10px;
        flex-shrink: 0;

        &.before {
          @media (max-width: 768px) {
            display: none;
          }
        }

        @media (min-width: 768px) {
          width: 102px;
          min-height: 102px;
        }

        @media (min-width: 992px) {
          width: 138px;
          min-height: 138px;
        }

        @media (min-width: 1200px) {
          width: 162px;
          min-height: 162px;
        }

        &:not(:first-of-type) {
          margin-left: -1px;
        }

        &.active {
          background-color: #e3f6ff;
          .calendar-day__title {
            color: #5b5b5b;
            &.today {
              color: #1a7ef7;
            }
          }
        }

        &.off {
          background-color: #efeff4;

          .calendar-day__information {
            &:before {
              background-color: #979797;
            }
          }
        }

        .calendar-day__title {
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          color: #979797;

          &.today {
            color: #1a7ef7;
          }
        }

        .calendar-day__link {
          margin-top: 7px;
          font-weight: bold;
          font-size: 12px;
          line-height: 16px;
          color: #094fa3;

          &:hover {
            cursor: pointer;
            text-decoration: underline;
          }

          .fas {
            font-size: 10px;
            margin-left: 5px;
            color: #094fa3;
          }
        }
      }
    }
  }
}

.filter-wrapper {
  box-shadow: 0px 2px 20px rgba(9, 79, 163, 0.25);
  border-radius: 5px;
  padding: 20px 15px 20px 30px;

  .filter-title {
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-align: left;
    color: #094fa3;
    border-bottom: 1px solid #d0e5f0;
    margin-left: -30px;
    margin-right: -15px;
    padding-left: 30px;
    padding-right: 15px;
    padding-bottom: 15px;
    margin-bottom: 15px;
  }
}

.filter-list {
  list-style-type: none;
  padding: 0;
  margin: 0;

  .filter-list__item {
    position: relative;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #979797;
    margin-bottom: 15px;
    text-align: left;
    &:last-child {
      margin-bottom: 0;
    }
    &.active {
      color: #094fa3;
      &:before {
        content: '';
        width: 6px;
        height: 6px;
        border-radius: 100%;
        position: absolute;
        top: 7px;
        left: -15px;
        background-color: #094fa3;
      }
    }
  }
}

.calendar-mobile-wrapper {
  box-shadow: 0px 2px 20px rgba(9, 79, 163, 0.25);
  border-radius: 5px;
  overflow: hidden;
  .calendar-mobile-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    color: #ffffff;
    text-align: center;
    background-color: #1a7ef7;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (min-width: 768px) {
      padding: 15px 30px;
    }
  }

  .calendar-mobile-title-nav {
    width: 7px;
    height: 14px;
    background-size: cover;
    background-position: center;
    cursor: pointer;
    &.calendar-mobile-title-prev {
      background-image: url('../../assets/img/next/svg/filter-prev.svg');
    }

    &.calendar-mobile-title-next {
      background-image: url('../../assets/img/next/svg/filter-next.svg');
    }
  }

  .calendar-mobile-head-item {
    display: flex;
    justify-content: center;
    font-size: 14px;
    line-height: 18px;
    color: #060606;
    width: 30px;
    flex: 0 0 auto;
    margin: 3px;
    font-weight: normal;
    @media (min-width: 768px) {
      width: 45px;
      margin: 4px;
    }
  }

  .calendar-mobile-head {
    display: flex;
    justify-content: flex-end;
    padding-top: 17px;
    padding-right: 17px;
    @media (min-width: 768px) {
      padding-right: 26px;
    }
  }

  .calendar-mobile-body {
    padding: 5px 12px 15px 12px;
    @media (min-width: 768px) {
      padding: 15px 21px 26px 26px;
    }
  }

  .calendar-mobile-row {
    display: flex;
    justify-content: flex-end;
    &.start {
      justify-content: flex-start;
      @media (min-width: 768px) {
        margin-left: -8px;
      }
    }
  }

  .calendar-mobile-item {
    width: 30px;
    height: 30px;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: #7f7f7f;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f8f8f8;
    border-radius: 4px;
    flex: 0 0 auto;
    border: 1px solid #f8f8f8;
    margin: 3px;
    @media (min-width: 768px) {
      width: 45px;
      height: 45px;
      font-size: 16px;
      line-height: 16px;
      margin: 4px;
    }
    &.disable {
      cursor: not-allowed;
    }
    &.active {
      cursor: pointer;
      background-color: rgba(166, 199, 255, 0.48);
      color: #0b7ed2;
      border: 1px solid rgba(26, 126, 247, 0.48);
      &:hover {
        background-color: #1a7ef7;
        color: #ffffff;
        border: 1px solid #1a7ef7;
        opacity: 1;
      }
    }
    &.end {
      opacity: 0.48;
      border: 1px solid #d6d6d6;
      color: #7f7f7f;
    }
  }

  .calendar-mobile-footer {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    margin-top: 15px;
    padding: 0 30px;
    color: #979797;
  }
}

.global-loader-wrapper {
  position: fixed;
  top: calc(50% - 40px);
  left: calc(50% - 40px);
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;

  img {
    width: 80px;
    height: 80px;
  }
}

.footer .copyright-container {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  @media (min-width: 1200px) {
    align-items: center;
  }
}

.copyright {
  padding: 25px 10px 0 0;
  font-weight: 500;
  order: 2;
  @media (min-width: 768px) {
    text-align: center;
    order: 1;
  }

  @media (min-width: 1200px) {
    text-align: left;
  }
}
.google-map-wrapper {
  width: 100%;
  height: 527px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 767px) {
    height: 330px;
  }

  .google-map-overlay {
    padding: 12px;
    max-width: 300px;
    max-height: 300px;
    overflow-y: auto;

    .google-map-overlay__element {
      &:not(:first-of-type) {
        margin-top: 15px;
      }

      .google-map-overlay__title {
        font-size: 16px;
        font-weight: bold;
        color: #08233b;
        text-align: left;

        a {
          color: inherit;
        }
      }

      .google-map-overlay__content {
        margin-top: 3px;
        font-size: 14px;
        color: #08233b;
        text-align: left;
      }

      .google-map-overlay__rating-list {
        background-color: #efeff4;
        border-radius: 4px;
        margin-top: 5px;
        padding: 7px 10px 9px 10px;
        display: inline-flex;
        align-items: center;
        min-width: 113px;
        height: 40px;

        .rating-list {
          display: flex;
          padding: 0;
          margin: 0;
          align-items: flex-start;
          list-style-type: none;

          .rating-list-item {
            margin-right: 1px;
            display: flex;
            align-items: center;

            &:last-child {
              margin-right: 0;
            }

            .fas {
              font-size: 20px;
              color: #ff9501;
            }

            .far {
              font-size: 20px;
              color: #d8d8d8;
            }
          }
        }

        .rating-numeral {
          font-weight: bold;
          font-size: 16px;
          color: #979797;
          margin-left: 6px;

          strong {
            font-size: 20px;
            color: #1d1b1b;
          }
        }
      }
    }
  }
}

.bot-wrapper {
  width: 100%;
  padding-top: 55px;
  margin: auto;

  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 950px;
  }

  @media (min-width: 1200px) {
    max-width: 1170px;
  }

  .main-title {
    font-family: IBMPlexSans, -apple-system, Roboto, Arial, sans-serif;
    margin-bottom: 20px;
    font-weight: 700;

    @media (min-width: 992px) {
      text-align: left;
      font-size: 36px;
    }

    @media (min-width: 576px) {
      font-size: 32px;
    }
  }

  .bot-about {
    padding-bottom: 55px;

    @media (min-width: 1200px) {
      padding-bottom: 0;
    }

    + .main-img {
      display: none;

      img {
        @media (min-width: 1200px) {
          height: 568px;
        }

        @media (min-width: 992px) {
          height: 458px;
        }
      }

      @media (min-width: 1200px) {
        height: 568px;
      }

      @media (min-width: 992px) {
        display: block;
        padding: 0;
      }
    }

    .main-img img {
      height: 286px;
      width: 286px;
    }

    .bot-description {
      padding: 0;

      p {
        font-size: 16px;
        color: #585f66;
        margin-left: 5px;
        line-height: 28px;
        margin-bottom: 25px;
      }

      .bot-list-wrapper {
        @media (min-width: 768px) {
          display: flex;
        }

        .bot-list {
          list-style-type: none;
          margin: 0 0 25px;
          padding: 0;

          @media (min-width: 768px) {
            width: 50%;
            margin: 0;
          }

          .bot-list__item {
            margin-bottom: 25px;
            padding-right: 20px;

            &:last-child {
              margin-bottom: 0;
            }
          }

          .bot-list__item-about {
            display: flex;
            align-items: center;
          }

          .bot-list__item-icon {
            flex-shrink: 0;
            margin-right: 10px;
            width: 30px;
          }

          .bot-list__item-description {
            font-size: 14px;
            line-height: 18px;
            font-weight: 500;
            color: #333333;
          }

          .bot-list__item-more {
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            color: #dc8d29;
            padding: 5px;
            background-color: #fff0db;
            border-radius: 5px;
            display: inline-flex;
            margin-left: 40px;
            margin-top: 8px;
          }
        }
      }
    }
  }

  .bot-btn {
    margin-top: 35px;
    display: flex;
    justify-content: space-between;
    padding: 0;

    button {
      img {
        margin-right: 10px;
      }

      @media (min-width: 576px) {
        height: 50px;
        font-size: 18px;
        &:first-child {
          min-width: 219px;
          padding-left: 27px;
          padding-right: 32px;
          margin-right: 30px;
        }
        &:nth-child(2) {
          min-width: 196px;
          padding-left: 36px;
          padding-right: 36px;
        }
      }
    }
  }

  .bot-btn-simplified {
    button,
    span {
      margin: 5px;
    }

    button {
      img {
        margin-right: 10px;
      }
    }
  }

  .main-img {
    text-align: center;
  }
}

.popupBot {
  max-width: 600px !important;

  .modal-header,
  .modal-body {
    padding: 0;
    border: none;
  }

  .modal-header .close {
    margin: -2rem -2rem -1rem auto;
    @media (min-width: 768px) {
      margin: -1rem -1rem -1rem auto;
    }
  }

  .modal-content {
    padding: 15px 15px 20px;
    @media (min-width: 576px) {
      padding: 30px 30px 40px;
    }
  }
  .modal_title {
    padding: 0;
    font-weight: bold;
    font-size: 28px;
    margin-bottom: 0;
    border-bottom: none;
    @media (min-width: 768px) {
      padding: 5px 100px 33px 10px;
      font-size: 30px;
      line-height: 40px;
    }
  }
  .close {
    font-size: 45px;
    font-weight: 400;
    color: #6c757d;
    margin: -15px -5px 0 0;
    padding: 0;
  }
  .modal-body {
    img {
      display: block;
      max-width: 365px;
      width: 100%;
      margin-bottom: 20px;
      margin-top: 10px;
      @media (min-width: 576px) {
        margin-bottom: 41px;
        margin-top: 0;
      }
    }
    .installBtn {
      display: flex;
      justify-content: center;
      flex-direction: row;
      button {
        margin-bottom: 15px;
        @media (min-width: 768px) {
          width: 250px;
          height: 50px;
          font-size: 18px;
          margin-bottom: 0;
        }
      }
      .later {
        background-color: #f8f8f8;
        &:hover {
          background-color: #6c757d;
        }
      }
    }
  }
}

.panelBot {
  width: 100%;
  margin-bottom: -135px;
  margin-top: 20px;
  overflow: hidden;
  height: 170px;
  display: flex;
  align-items: flex-end;

  @media (max-width: 991px) {
    height: 150px;
  }

  @media (max-width: 768px) {
    height: 250px;
  }

  @media (max-width: 502px) {
    height: 270px;
  }

  @media (max-width: 426px) {
    height: 290px;
  }

  .alert {
    background: #e3f6ff;
    border: 1px solid #d0e5f0;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
    padding: 0 !important;
    margin: 0;
    position: relative !important;
    z-index: inherit !important;

    @media (min-width: 768px) {
      height: 114px;
    }

    .alert-heading {
      height: 100%;
      padding: 20px 0 20px 20px;
      font-size: 18px;
      font-weight: bold;
      line-height: 28px;
      text-align: left;
      display: flex;
      align-items: center;

      @media (min-width: 768px) {
        padding: 15px;
      }

      @media (min-width: 992px) {
        padding: 20px 0 20px 30px;
      }
    }

    .alert-btn {
      height: 100%;
      padding: 0px 21px 20px 19px;

      @media (min-width: 768px) {
        padding: 32px 0 32px 0;
        justify-content: center;
      }

      @media (min-width: 992px) {
        display: flex;
        align-items: center;
      }

      button {
        min-width: 115px;
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (min-width: 375px) {
          min-width: 135px;
        }

        @media (min-width: 768px) {
          min-width: 167px;
        }

        a {
          color: white;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }

    .alert-img {
      padding: 0;
      text-align: center;
      display: flex;
      align-items: center;

      @media (min-width: 427px) {
        margin-top: -40px;
        display: block;
      }

      @media (min-width: 768px) {
        padding: 0 50px 0 7px;
        margin-top: -35px;
      }

      @media (min-width: 992px) {
        padding: 0 50px 0 7px;
        margin-top: -75px;
      }

      img {
        width: 135px;

        @media (min-width: 427px) {
          width: 182px;
        }

        @media (min-width: 768px) {
          width: 200px;
        }

        @media (min-width: 992px) {
          width: 293px;
        }
      }
    }

    .close {
      display: inline-block;
      font-size: 50px;
      padding: 0 5px 0 0;
      font-weight: 400;
      outline: none;
    }
  }
}

.login-btn-loader {
  min-width: 170px;
  padding: 6px 12px;
  min-height: 48px;

  &.login-btn {
    background: transparent;
  }

  img {
    width: 35px;
    height: 35px;
  }
}

.user-cabinet-navigation__item-tooltip {
  position: absolute;
  left: 0;
  top: calc(100% + 10px);
  width: 100%;
  background-color: #fff0db;
  border: 1px solid #f6c38b;
  border-radius: 5px;
  padding: 6px 16px 10px 9px;
  display: flex;
  opacity: 1;
  white-space: pre-wrap;
  user-select: none;
  cursor: default;
  pointer-events: none;
  z-index: 1;

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 23px;
    width: 0;
    height: 0;
    border-style: solid;
  }
  &:after {
    top: -11px;
    border-color: transparent transparent #fff0db transparent;
    border-width: 6px;
  }
  &:before {
    top: -12px;
    border-color: transparent transparent #f6c38b transparent;
    border-width: 6px;
  }

  .user-cabinet-navigation__item-tooltip-description {
    font-size: 14px;
    line-height: 20px;
    color: #dc8d29;
    text-align: left;
    font-weight: normal;
  }

  .user-cabinet-navigation__item-tooltip-icon {
    margin-right: 10px;
    i {
      font-size: 16px;
      color: #dc8d29;
    }
  }
}

.user-cabinet-error-message {
  background-color: #fff0db;
  border: 1px solid #f6c38b;
  border-top: none;
  box-sizing: border-box;
  border-radius: 5px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: 15px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-top: -30px;
  .user-cabinet-error-message-icon {
    margin-right: 10px;
    i {
      font-size: 16px;
      color: #dc8d29;
    }
  }

  .user-cabinet-error-message-description {
    font-size: 14px;
    line-height: 20px;
    color: #dc8d29;
  }
}

.cabinet-nothing-found {
  border: 1px dashed #cecece;
  border-radius: 6px;
  font-weight: 500;
  font-size: 18px;
  line-height: 34px;
  color: #cecece;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
  padding: 40px 15px;
  @media (min-width: 768px) {
    padding: 80px 15px;
  }
}

.cabinet-no-records {
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px dashed #cecece;
  border-radius: 6px;
  padding: 30px 15px;
  @media (min-width: 768px) {
    padding: 50px 15px;
  }

  @media (min-width: 992px) {
    padding: 80px 15px;
  }

  .cabinet-no-records-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 34px;
    color: #094fa3;
    margin-bottom: 15px;
  }

  .cabinet-no-records-btn {
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #ffffff;
    padding: 16px 25px;
  }
}

.streaming-records-title-records-wrapper {
  .streaming-records-title-records-item {
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: #094fa3;

    &:not(:first-of-type) {
      margin-left: 10px;
    }

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }

    i {
      margin-right: 5px;
      font-size: 14px;
    }
  }
}

.not-found-page-wrapper {
  padding: 20px;
  text-align: center;

  .not-found-page-title {
    font-weight: 700;
    font-size: 24px;
  }

  .not-found-page-content {
    font-size: 18px;
  }
}

.search-field-dropdown-focus {
  display: flex;
  align-items: center;
  .search-field-dropdown-focus-icon {
    width: 50px;
    height: 50px;
    background-image: url('../../assets/img/next/svg/medic.svg');
    background-size: cover;
    margin-right: 15px;
    flex: 0 0 auto;
  }

  .search-field-dropdown-focus-description {
    font-size: 14px;
    line-height: 22px;
    color: #979797;
  }
}

.schedule-body-no-result-content {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #979797;
  margin-left: 10px;
  @media (max-width: 768px) {
    background-color: #efeff4;
    border-radius: 4px;
    text-align: center;
    padding: 4px 15px;
    font-size: 12px;
    margin-left: 0;
  }
  i {
    font-size: 14px;
    color: #979797;
    margin-right: 10px;
    @media (max-width: 768px) {
      display: none;
    }
  }
}

.schedule-body-no-result-appointment {
  padding: 13px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: rgba(51, 51, 51, 0.7);
  margin-top: 15px;
  background-color: #efeff4;
  border-radius: 5px;
  i {
    color: #094fa3;
    font-size: 12px;
  }
  strong {
    color: #094fa3;
  }
}

.sticky-wrapper {
  position: relative;
  z-index: 2;
}

.sticky {
  background-color: #edf4f8;
}

.remained-medicines-link {
  border: 2px solid #1a7ef7;
  border-radius: 4px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  padding: 10px 15px;
  color: #007bff;

  &:hover {
    cursor: pointer;
    color: #007bff;
  }

  img {
    margin-right: 5px;
  }
}

.appointment-modal {
  .modal-title {
    font-weight: bold;
    font-size: 24px;
    line-height: 31px;
    color: #1d1b1b;
    @media (max-width: 767px) {
      padding-right: 50px;
    }
  }

  .header-line {
    border-color: #d0e5f0;
  }

  .modal-content {
    overflow: hidden;
    @media (max-width: 767px) {
      padding: 15px;
    }
  }

  .doctor p,
  .specialization p {
    font-weight: 500;
  }

  .institution p {
    font-weight: 500;
    color: #094fa3 !important;
  }

  .radio__text {
    &:before {
      top: 15px;
      width: 24px;
      height: 24px;
      @media (min-width: 768px) {
        width: 30px;
        height: 30px;
        top: 12px;
      }
    }

    &:after {
      content: url('../../assets/img/next/checkbox-arrow-mobile.svg');
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 15px;
      left: 15px;
      border: 1px solid #1a7ef7;
      background-color: #1a7ef7;
      width: 24px;
      height: 24px;
      @media (min-width: 768px) {
        content: url('../../assets/img/next/checkbox-arrow.svg');
        width: 30px;
        height: 30px;
        top: 12px;
      }
    }
  }

  .modal-header {
    .close {
      top: 10px;
      right: 10px;
    }
  }
}

.application-wrapper {
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(75.65deg, #509227 0%, #92bf1f 169.79%);

  .application-logo {
    text-align: center;
  }

  .application-title {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.03em;
    color: #ffffff;
    margin-top: 25px;
    margin-bottom: 35px;
    text-align: center;
  }

  .application-list {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .application-list__item {
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
    img {
      max-width: 100%;
    }
  }
}

.personal-review-wrapper {
  .personal-review {
    position: relative;
  }

  .personal-review-list__item-mobile {
    padding-bottom: 10px;
    margin-bottom: 15px;
    &:before {
      bottom: 0;
    }
  }

  .personal-review-list {
    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: #fff;
      bottom: 0;
      left: 0;
      z-index: 1;
      border-radius: 100%;
    }
  }
}

.ReactModal__Content--after-open {
  background-color: rgba(99, 136, 157, 0.7) !important;
}

.auth_code_wrapper {
  align-items: normal;
}

.apps-list {
  display: flex;
  align-items: center;
  padding-left: 0;
  margin-top: 20px;
  margin-bottom: 0;
  width: 100%;

  @media (min-width: 768px) {
    width: auto;
  }

  @media (min-width: 992px) {
    margin-top: 0;
  }

  .apps-list__item {
    &:last-child {
      margin-left: 15px;
    }
  }
}

.user-cabinet-navigation--vaccination {
  padding: 15px 0 30px 0;
  @media (min-width: 768px) {
    padding: 60px 0 72px 0;
  }
  @media (min-width: 1200px) {
    padding: 120px 0 134px 0;
  }
  .user-cabinet-wrapper {
    background-color: transparent;
    padding: 0;
    margin-left: -15px;
    margin-right: -15px;
    margin-top: 0;
  }

  .user-cabinet-navigation__item {
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    color: #333333;
    padding: 10px;
    @media (min-width: 768px) {
      margin-bottom: 0;
      height: 100%;
    }

    .error {
      font-size: 12px;
      line-height: 16px;
      color: #dc8d29;
      margin-top: 6px;
      .fas {
        font-size: 14px;
        color: #dc8d29;
        margin-right: 5px;
      }
    }
    &.disabled {
      opacity: 1;
      box-shadow: none;
      height: auto;

      .user-cabinet-navigation__item-description-content {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #cecece;
        @media (min-width: 768px) {
          font-size: 18px;
          line-height: 23px;
        }
      }
      .user-cabinet-navigation__item-description {
        color: #cecece !important;
      }
    }
  }

  .title {
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: #1d1b1b;
    margin-bottom: 20px;
    @media (min-width: 768px) {
      font-size: 30px;
      line-height: 39px;
      margin-bottom: 30px;
    }
  }

  .user-cabinet-navigation__item-description-content {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #333333;
    @media (min-width: 768px) {
      font-size: 16px;
      line-height: 21px;
    }
  }
}

.header.header--vaccination {
  padding: 19px 0 18px 0;
  background: linear-gradient(0deg, #fafafa, #e8f1f7);
  &:before {
    display: none;
  }
}

.header,
.header--vaccination {
  .btn--vaccination {
    background-color: #fe4a49;
    border-radius: 4px;
    padding: 16px 18px;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;
    height: 50px;
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: 20px;
    @media (max-width: 767px) {
      display: none;
    }
    &:hover {
      background-color: #fe4a49;
    }
    .fas {
      font-size: 16px;
      color: #ffffff;
      margin-right: 10px;
    }
  }
}

.breadcrumbs-wrapper--vaccination {
  margin-top: 0;
  display: flex;
  align-items: center;
  .breadcrumbs-item {
    padding: 0;
  }
}

.no-divisions-placeholder {
  height: 100%;
  border: 1px dashed #cecece;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: #666666;
  padding: 15px;
  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 31px;
    margin-left: 15px;
    margin-right: 15px;
  }
  @media (min-width: 1200px) {
    margin-left: 0;
    margin-right: 0;
  }
}

.medic-feature-wrapper--vaccination {
  .medic-feature-profile-information__dropdown {
    display: none !important;
  }

  .medic-feature-profile-information__calendar {
    margin-left: 0;
  }

  @media (max-width: 767px) {
    padding-top: 15px;
    padding-bottom: 30px;
    .pagination {
      padding: 0;
    }

    .user-cabinet-navigation__item-description-content {
      &:before {
        display: none;
      }
    }
  }

  .container {
    @media (max-width: 767px) {
      padding: 0 20px;
    }
  }

  .user-cabinet-header-row {
    @media (max-width: 767px) {
      margin-left: -15px;
      margin-right: -15px;
      .col-md-5 {
        order: 2;
      }

      .offset-md-2 {
        order: 1;
      }
    }
  }

  .institution-about__address {
    .institution-about__icon-wrapper {
      @media (max-width: 767px) {
        margin-left: -20px;
      }
    }

    .institution-about__description-title {
      @media (max-width: 767px) {
        font-size: 14px;
        line-height: 18px;
      }
    }

    .institution-about__description-direction {
      @media (max-width: 767px) {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }

  .navigation-container {
    display: none;
    @media (max-width: 767px) {
      padding: 0;
      display: block !important;
    }
  }

  .navigation {
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: column;
    height: auto;
    @media (max-width: 767px) {
      border-bottom: 0;
      padding: 5px 0 0 0;
    }

    @media (min-width: 768px) {
      padding: 0 15px;
      flex-direction: row;
    }

    @media (min-width: 1200px) {
      padding: 0;
    }

    .navigation-helpers {
      height: auto;
    }

    .schedule-wrapper {
      margin-left: auto;
    }

    .title {
      font-weight: bold;
      font-size: 20px;
      line-height: 26px;
      color: #1d1b1b;
      @media (max-width: 767px) {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 15px;
        width: 100%;
        text-align: left;
      }
    }
  }

  .schedule-wrapper {
    padding: 0;
  }

  .medic-feature-profile {
    width: 100%;
    @media (max-width: 767px) {
      padding: 15px;
    }

    @media (min-width: 768px) {
      padding: 15px;
    }
    @media (min-width: 1200px) {
      padding: 30px;
    }
  }

  .schedule-body--mobile {
    @media (min-width: 768px) and (max-width: 992px) {
      margin-left: -9px !important;
    }
  }

  .no-doctors-placeholder {
    border: 1px dashed #cecece;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: #666666;
    min-height: 136px;
    padding: 15px;
    @media (min-width: 768px) {
      min-height: 250px;
      font-size: 24px;
      line-height: 31px;
      margin-left: 15px;
      margin-right: 15px;
    }
    @media (min-width: 1200px) {
      min-height: 372px;
      margin-left: 0;
      margin-right: 0;
    }
  }

  .user-cabinet-navigation {
    padding: 0;
  }

  .navigation-helpers {
    @media (max-width: 767px) {
      width: 100%;
      display: block;
    }
    .calendar {
      @media (max-width: 767px) {
        width: 100%;
        margin-left: 0;
        background-color: #ffffff !important;
        border: 1px solid #d0e5f0;
        box-shadow: 0px 2px 4px rgba(9, 79, 163, 0.15);
        border-radius: 5px;
        padding: 8px 15px;
        display: flex;
        justify-content: space-between;
        .far {
          margin-right: 10px;
          color: #094fa3 !important;
        }

        .calendar-info {
          margin-right: auto;
        }

        .calendar-info-date {
          text-align: left;
          color: rgb(9, 79, 163) !important;
        }

        .calendar-info-day {
          font-size: 12px;
          line-height: 16px;
          text-align: left;
          color: rgb(91, 91, 91) !important;
        }

        svg {
          rect {
            fill: rgb(9, 79, 163) !important;
          }
        }
      }

      @media (min-width: 768px) {
        .far {
          margin-right: 10px;
        }

        .calendar-info {
          margin-right: 15px;
        }
      }
    }
  }

  .institution-about__information {
    @media (max-width: 767px) {
      padding-top: 0;
    }
  }

  .medic-feature-profile-status {
    @media (max-width: 767px) {
      margin-top: -45px;
      margin-left: -15px;
      margin-bottom: 0;
    }
  }

  .sticky-wrapper {
    .navigation-container {
      display: block;
    }
    @media (max-width: 767px) {
      width: 100%;
      .navigation-container {
        display: none !important;
      }
    }
  }

  .feature-card__item {
    @media (max-width: 767px) {
      margin-top: 60px;
      .medic-feature-profile {
        margin-top: -15px;
      }
    }
  }
}

.user-cabinet-navigation--vaccination-search {
  .title {
    font-weight: bold;
    font-size: 24px;
    line-height: 31px;
    color: #1d1b1b;
    @media (max-width: 768px) {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 15px;
    }
  }

  .container {
    padding: 0 20px;
  }

  .sub-title {
    font-size: 14px;
    line-height: 18px;
    color: #333333;
    margin-top: -15px;
  }

  .dropdown-toggle {
    min-height: 60px;
    padding-left: 25px !important;
    @media (max-width: 767px) {
      min-height: 50px;
      padding-left: 15px !important;
    }
  }

  .user-cabinet-navigation__item-description-content {
    color: #979797 !important;
    @media (max-width: 767px) {
      font-size: 14px !important;
      line-height: 18px !important;
      &:before {
        display: none;
      }
    }
  }

  .user-cabinet-navigation__item-description {
    padding-left: 25px !important;
    min-height: 60px;
    @media (max-width: 767px) {
      min-height: 50px;
      padding-left: 15px !important;
    }
  }

  .pagination {
    padding: 0;
  }

  .institution-about__icon-wrapper {
    @media (max-width: 767px) {
      margin-left: -20px;
    }
  }

  .institution-about__description-title {
    font-size: 16px;
    line-height: 21px;
    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 18px;
    }
  }

  .institution-about__description-appellation,
  .institution-about__description-direction a {
    @media (max-width: 767px) {
      font-size: 12px;
      line-height: 16px;
    }
  }

  .institution-about__description-direction {
    font-size: 14px;
    line-height: 18px;
  }

  .user-cabinet-navigation__item.dropdown button .user-cabinet-navigation__item-description-content:before {
    @media (max-width: 767px) {
      display: none;
    }
  }
}

.choose-district {
  border: 1px dashed #cecece;
  border-radius: 6px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 767px) {
    .title {
      margin: 0;
      padding: 25px 15px;
    }
    .user-cabinet-navigation__item {
      display: none;
    }
  }
  .title {
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    color: #333333;
    margin-bottom: 25px;
  }

  .user-cabinet-navigation__item {
    max-width: 370px;
  }
}

.show-access {
  .modal-content {
    padding: 0;
    border: none;
    background-color: transparent;
    font-weight: bold;
    font-size: 24px;
    line-height: 31px;
    color: #ffffff;
  }

  .modal-dialog {
    position: absolute;
    max-width: 700px;
    top: 106px;
    left: 14%;
    &:before {
      content: '';
      position: absolute;
      left: -36px;
      top: 0;
      width: 16px;
      height: 26px;
      background-image: url('../../assets/img/arrow-access.svg');
    }
  }
}

.my-ambulatory-note {
  background-color: #fff0db;
  border: 1px solid #f6c38b;
  border-radius: 5px;
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 20px;
  color: #dc8d29;
  padding: 5px 8px;
  margin-top: 28px;
  max-width: 140px;
  .fas {
    margin-right: 5px;
    font-size: 14px;
  }

  @media (max-width: 767px) {
    position: absolute;
    top: 3px;
    left: 90px;
    margin-top: 0;
  }
}

.direction-area {
  border: none !important;
  .direction-area__title {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #333333;
    margin-bottom: 10px;
    @media (min-width: 768px) {
      font-weight: bold;
      font-size: 16px;
      line-height: 25px;
      margin-bottom: 9px;
      letter-spacing: normal;
    }
  }

  .direction-area__info {
    font-size: 12px;
    line-height: 16px;
    color: #8e8e8e;
    @media (min-width: 768px) {
      letter-spacing: 0.25px;
    }
  }

  input {
    width: 100% !important;
    border-color: #6200ee !important;
    outline: none;
    font-size: 16px;
    line-height: 24px;
    max-width: 250px;
    letter-spacing: 2px;
    padding: 18px 10px 12px 10px !important;
    @media (min-width: 768px) {
      max-width: 328px;
      letter-spacing: 5px;
      padding: 19px 28px 13px 26px !important;
    }

    &::placeholder {
      letter-spacing: 2px;
      color: #bcbcbc;
      font-size: 16px;
      line-height: 24px;
      @media (min-width: 768px) {
        letter-spacing: 5px;
      }
    }
  }

  .checkbox__text {
    font-size: 12px;
    line-height: 16px;
    color: #8e8e8e;
    @media (min-width: 768px) {
      font-size: 14px;
      line-height: 18px;
      color: #333333;
    }
    &:before {
      content: url('../../assets/img/white-check.svg');
      top: 8px;
    }

    &:after {
      border-width: 2px;
      border-color: #efeff4;
    }
  }

  .checkbox input:checked + .checkbox__text:after {
    background-color: #1a7ef7;
    border-color: #1a7ef7;
  }
}

.validation_error {
  color: red;
  font-size: 12px;
  margin-top: -1rem;
  margin-bottom: 0.5rem;
}
